import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import TextInputField from "../../common/inputFields/TextInputField";
import { useNavigate } from "react-router-dom";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import { values } from "lodash";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";
import Map from "../../components/map/Map";

const initialValues = {
  firstAndLastName: "",
  mobileNo: "",
  inquiry: "",
};

const ContactUs = () => {

      // Coordinates for Yemen
      const triangleCoordinates = [
        [24.3870994, 54.2289489],
        [24, 55.8532749],
        [25.3, 55.3532749]
      ];

  const [apiError, setApiError] = useState('');
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState('');
  const [validateOnSubmit, setValidateOnSubmit] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    setValidateOnSubmit(true);
    const params = {
      name: values.firstAndLastName,
      phone: values.mobileNo,
      message: values.inquiry
    }
    setLoader(true);
    request(
      Constant.URL.ContactUs,
      "post",
      params,
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
         setApiError(error.response.data.message);
        } else {
          if (result.status == 200) {
             setSuccess(result.data.message);
             window.location.reload()
             
          } else if (result.status == 401) {
            setApiError(result.data.message);
          } else {
            setApiError(result.response.data.message);
          }
        }
      }
    );
  };

  const validationSchema = Yup.object({
  firstAndLastName: Yup.string().required("First and last name is required").min(3, "First And Last Name must be at least 3 characters").matches(/^[A-Za-z\s]+$/, 'First And Last Name must contain only alphabetic characters'),
  mobileNo: Yup.string()
      .required("Phone Number is required")
      .min(7, 'Phone number must atleast 7 digits')
      .max(12, 'Phone number not more then atleast 12 digits')
      .matches(/^[0-9]+$/, "Phone number can only contain numbers"),
  inquiry: Yup.string().required("Inquiry is required"),
  });


  return (
    <div className="contact-wrapper">
      <Loader isLoading={loader}/>
      <div className="custom-container mb-10 md:mb-20 ">
        <h1 className="section-title"><Language>how_can_we_help</Language></h1>
        <Grid container className="box-parent mt-10">
          <Grid xs={12} sm={12} md={4} className="child-box">
            <div className="flex flex-col justify-center items-center">
              <h3 className="subheading"><Language>customer_support</Language></h3>
              <p className="box-date">
              <Language>monday_to_friday</Language> <br /> <Language>nineAm_sixPm</Language>
              </p>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={4} className="child-box">
            <div className="flex flex-col justify-center items-center">
              <h3 className="subheading"><Language>contact_us</Language></h3>
              <p className="box-date">
                <a href="mailto:'Info@yumfitme.com'">Info@yumfitme.com</a>{" "}
                <br /> <a href="tel:+971585708210">0585884930</a>
              </p>
            </div>
          </Grid>
          <Grid xs={12} sm={12} md={4} className="child-box">
            <div className="flex flex-col justify-center items-center">
              <h3 className="subheading"><Language>delivery_regions</Language></h3>
              <span className="box-date">
                <Language>uae</Language>
                {/* <br /> <Language>Dubai</Language>
                <br /><Language>Sharjah</Language> */}
              </span>
            </div>
          </Grid>
        </Grid>

        <Grid container className="md:mt-14 mt-11">
          <Grid xs={12} sm={4} md={4} className="contact-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleChange, errors, handleSubmit }) => (
                <Form>
                  <div>
                    <TextInputField
                      name="firstAndLastName"
                      type="firstAndLastName"
                      wrapperClassName="w-full"
                      inputClassName="form-control"
                      placeholder="first_and_last_name"
                      value={values.firstAndLastName}
                      onChange={handleChange('firstAndLastName')}
                      error={validateOnSubmit && errors.firstAndLastName}
                    />
                   {validateOnSubmit ? ( <div style={{color: 'red', fontSize: 12}}> {errors.firstAndLastName} </div>) : null}
                    <TextInputField
                      name="mobileNo"
                      type="number"
                      wrapperClassName="w-full"
                      inputClassName="form-control"
                      placeholder="phone_number"
                      value={values.mobileNo}
                      onChange={handleChange('mobileNo')}
                      error={validateOnSubmit && errors.mobileNo}
                    />
                    {validateOnSubmit ? ( <div style={{color: 'red', fontSize: 12}}>{errors.mobileNo} </div>) : null}
                    <TextInputField
                      wrapperClassName="w-full"
                      inputClassName="form-control"
                      placeholder="inquiry"
                      multiline
                      rows={4}
                      value={values.inquiry}
                      onChange={handleChange('inquiry')}
                      error={validateOnSubmit && errors.inquiry}
                    />
                  </div>
                  {validateOnSubmit ?( <div style={{color: 'red', fontSize: 12}}> {errors.inquiry} </div> ) : null}
                  <div className="mt-8 flex flex-col md:flex-row">
                    <Button
                      variant="contained"
                      type="submit"
                      className="next-btn"
                      onClick={() => {
                        setValidateOnSubmit(true);
                      }}
                    >
                      <Language>submit_inquiry</Language>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <div style={{color: 'red', fontSize: 15, display: 'flex', justifyContent: 'center'}}>{apiError ? apiError : null}</div>
            <div style={{color: 'green', fontSize: 15, display: 'flex', justifyContent: 'flex-start', marginTop: 10}}>{success ? success : null}</div>
          </Grid>
          <Grid xs={12} sm={8} md={8} className="pl-14 mobile-hide-map">
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14762.830823750794!2d70.76822925790404!3d22.326891562938897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1701678517602!5m2!1sen!2sin"
              width="100%"
              height="100%"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}

            <Map triangleCoordinates={triangleCoordinates} zoomValue={8} defaultLocation={true}/>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
export default ContactUs;
