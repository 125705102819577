import React from "react";
import LOGO from "../../assets/images/logo.png";
import Countdown from "../../common/CountDown/CountDown";
import Language from "../../common/Language";
import { Link, useNavigate } from "react-router-dom";

function SuccessPage(props) {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleNavigation = () => {
    if (token) {
      navigate("/");
    } else {
      navigate("/register");
    }
  };
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={LOGO} alt="Dummy Logo" className="w-48 h-36 mb-4" />
      <div className="text-6xl text-green-500 mb-2">&#10004;</div>
      <h2 className="text-2xl text-green-500 mb-2"><Language>payment_successful</Language></h2>
      <p className="text-gray-700"><Language>your_payment_has_been_successful</Language></p>
      <p className="text-gray-700 mt-10">
        <Language>back_to</Language>{" "}
        <Link to="/" className="text-blue-500 underline">
          <Language>home</Language>
        </Link>
      </p>
      <p className="text-gray-700 mt-10">
      <Language>redirecting_to_website</Language>{" "}
        <Countdown initialCount={3} onZero={() => handleNavigation()}>
          {(count) => (
            <p className="text-gray-700">
              {count !== 0 && (
                <p style={{ fontWeight: "bold" }} className="text-center">
                  ({count})
                </p>
              )}
            </p>
          )}
        </Countdown>
      </p>
    </div>
  );
}

export default SuccessPage;
