import React, { useState, useEffect } from "react";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";

const PrivacyPolicy = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const fetchPrivacyPolicyData = () =>
  {
    setLoader(true);
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.PrivacyPolicy,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
          setApiError(error.response.data.message);
        } else {
          if (result.status == 200) {
            setPrivacyPolicy(result.data.data);
          }  else {
            setApiError(result.response.data.message);
          }
        }
      }
    );
  }

  useEffect(() => {
    fetchPrivacyPolicyData();
  }, []);
  
  return (
    <>
     <Loader isLoading={loader}/>
     {privacyPolicy ? 
      <div className="main-wrapper w-full">
        <div className="custom-container">
          <h1 className="section-title">{privacyPolicy.spotlight_title}</h1>
          <ul className="policy-content">
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy.privacy.desc }} />
          </ul>
        </div>
      </div>
       : apiError}
    </>
  );
};

export default PrivacyPolicy;
