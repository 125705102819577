import React, { useState } from "react";
import TextInputField from "../../common/inputFields/TextInputField";
import { useNavigate, useLocation } from "react-router-dom";
import loginImage from "../../assets/images/your plan.png";
import { Container, Grid } from "@mui/material";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { values } from "lodash";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";
import { ToastContainer, toast } from "react-toastify";

const initialValues = {
  password: "",
  confirmPassword: "",
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [apiError, setApiError] = useState('');
  const [loader, setLoader] = useState(false);

  const handleSubmit = (values) => {
    const params = {
      token: token,
      password: values.password,
      confirm_password: values.confirmPassword,
    };
    setLoader(true);
    request(
      Constant.URL.newPassword,
      "post",
      params,
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
          setApiError(error.response.data.message);
        } else {
          if (result.status === 200) {
            if (result.data.status && result.data.status === 401)
            {
              setApiError(result.data.error);
            }
            navigate("/login", {
              state: { token: result.data.token },
            });
          } else if (result.status === 401) {
            setApiError(result.data.message)
          } else {
            setApiError(result.response.data.message);
          }
        }
      }
    );
  }

  const validationSchema = Yup.object({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .test('passwords-match', 'Passwords must match', function (value) {
        return this.parent.password === value;
      }),
  });

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <Loader isLoading={loader}/>
      <Container className="custom-container">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className=" justify-start items-center hidden md:flex">
            <div className="login-image">
              <img src={loginImage} alt="" className="w-full h-full" />
            </div>
          </div>
          <div>
            <div className="login-right">
              <div className="login-details xl:mr-64">
                <h2 className="welcome-text text-center mb-4">
                  <Language>change_password</Language>
                </h2>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleChange, errors, handleSubmit }) => (
                    <Form>
                      <TextInputField
                        name="password"
                        type="password"
                        wrapperClassName="w-full mb-0"
                        inputClassName="form-control"
                        placeholder="password"
                        value={values.password}
                        onChange={handleChange("password")}
                      />
                      <div style={{ color: "red", fontSize: 12 }}>
                        {errors.password}
                      </div>
                      <TextInputField
                        name="confirmPassword"
                        type="password"
                        wrapperClassName="w-full mb-0"
                        inputClassName="form-control"
                        placeholder="repeat_password"
                        value={values.confirmPassword}
                        onChange={handleChange("confirmPassword")}
                      />
                      <div style={{ color: "red", fontSize: 12 }}>
                        {errors.confirmPassword}
                      </div>
                      <button
                        className="w-full py-2 btn-success"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        <Language>change_password</Language>
                      </button>
                    </Form>
                  )}
                </Formik>
                <div
                  style={{
                    color: "red",
                    fontSize: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {apiError ? apiError : null}
                </div>
                <button
                  className="w-full mt-2 py-2 btn-forgot text-center"
                  type="button"
                  onClick={() => navigate("/register")}
                >
                  <Language>don't_have_an_account</Language>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ForgotPassword;
