import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";

export default function Blogs() {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [blogByTagData, setBlogByTagData] = useState([]);
  const [tabUrl, setTabUrl] = useState();
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabs = (url) =>
  {
    if (localStorage.getItem("tabUrl") === url)
    {
      localStorage.removeItem("tabUrl");
      window.location.reload();
    }
    else
    {
      setTabUrl(url);
      localStorage.setItem("tabUrl", url);
      setCurrentPage(1);
    }
  };

  const blogsByTag = (page) =>
  {
    setLoader(true);
    let tabUrl = localStorage.getItem("tabUrl");
    let blogUrl = Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.BlogByTag
    request(
      tabUrl ? (blogUrl +'/'+tabUrl+ '?page=' + page) : (blogUrl + '?page=' + page),
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
        } else {
          if (result.status == 200) {
            if (result.data.status === "500")
            {
              console.log('Something went wrong!');
            }
            else {
              setBlogByTagData(result.data.data);
            }
          } else {
          }
        }
      }
    );
  }
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    blogsByTag(page);
  };
  
  useEffect(() => {
    blogsByTag(currentPage);
  }, [tabUrl, currentPage]);

  useEffect(() => {
    let tempIndex = '';
    let tabUrl = localStorage.getItem("tabUrl");
    blogByTagData?.tags?.forEach((x, i) =>
    {
      if (x.id === tabUrl)
      {
        return tempIndex = i;
      }
    });
    setValue(tempIndex);
  }, [blogByTagData]);

  return (
    <div className="blog-main">
      <Loader isLoading={loader}/>
      <div className="custom-container">
        <h2 className="section-title"><Language>blog</Language></h2>
        <Grid container className="mt-10 custom-tab-blog md:px-16">
          <Grid xs={12} className="flex justify-center items-center">
          <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
            {
              blogByTagData?.tags?.map((x, index) => 
              <Tab label={x.title} key={index} onClick={() => handleChangeTabs(x.url)} />
              )
            }
            </Tabs>
          </Grid>
          <Grid xs={12} className="mt-5 mb-10">
              <Grid container>
              {blogByTagData?.blogs?.length > 0 ? (
                blogByTagData.blogs.map((item, index) => (
                  <Grid xs={12} sm={6} md={4} key={index}>
                    <div
                      className="blog-parent-box"
                      onClick={() =>
                        navigate('/blog-detail', {
                          state: { blogId: item.id }
                        })
                      }
                    >
                      <div className="blog-img mb-5">
                        <img src={item.thumbnail} alt="img" className="w-full h-full" />
                      </div>
                      <p className="blog-title">{item.title}</p>
                    </div>
                  </Grid>
                ))
              ) : (
                <Grid xs={12}>
                  <h2 className="text-center" style={{ fontSize: 24 }}>No data found</h2>
                </Grid>
              )}
              </Grid>
              <Grid container>
                <Grid
                  xs={12}
                  className="flex justify-center items-center blog-page mt-4"
                >
                  <Stack spacing={2}>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      count={blogByTagData?.blogs?.length > 0 ? blogByTagData?.no_of_pages: ""}
                      shape="rounded"
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Stack>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
