import { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Countdown = forwardRef(
  ({ children, initialCount, onTimeElapsed, onZero  }, ref) => {
    const [count, setCount] = useState(initialCount);

    useEffect(() => {
      const timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            onTimeElapsed?.();
            onZero?.();
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(timer);
    }, [onTimeElapsed, onZero]);

    useImperativeHandle(ref, () => ({
      resetCountdown: () => setCount(initialCount),
    }));

    return children(count);
  },
);

Countdown.propTypes = {
  children: PropTypes.func.isRequired,
  initialCount: PropTypes.number.isRequired,
  onTimeElapsed: PropTypes.func,
};

Countdown.displayName = 'Countdown';

export default Countdown;
