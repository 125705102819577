import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import * as yup from "yup";
import InputField from "./components/InputField";
import MultiStepForm, { FormStep } from "./components/MultiStepForm";
import SelectInput from "./components/SelectInput";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Map from "../../components/map/Map";
import { Grid, InputLabel } from "@mui/material";
import RadioInput from "../../common/inputFields/RadioInput";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";
import validatecustomerLeads from "../../utils/validateCustomerLeads";

const aggrement = [{ label: "I agree with", value: true }];

const mealsDays = [
  { label: <Language>mon</Language>, value: "Mon" },
  { label: <Language>tue</Language>, value: "Tue" },
  { label: <Language>wed</Language>, value: "Wed" },
  { label: <Language>thu</Language>, value: "Thu" },
  { label: <Language>fri</Language>, value: "Fri" },
  { label: <Language>sat</Language>, value: "Sat" },
];

// const masterLocations = [{ name: "Dubai", id: "dubai" }];`

const DemoRegistration = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [addressCategories, setAddressCategories] = useState([]);
  const [acceptTearmAndCondition, setacceptTearmAndCondition] = useState(0);
  const [preferredDeliveriedTime, setPreferredDeliveriedTime] = useState([]);
  const [areaTime, setAreaTime] = useState([]);
  const [cities2, setCities2] = useState([]);
  const [activityLevels, setActivityLevel] = useState([]);
  const [height, setHeight] = useState([]);
  const [goalWeight, setGoalWeight] = useState([]);
  const [currentWeight, setCurrentWeight] = useState([]);
  const [masterLocations, setMasterLocations] = useState([]);
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState("");
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const [latLng, setLatLng] = useState(localStorage.getItem("latLng"));
  const [isSetDeliveryPlaceValue, setIsSetDeliveryPlaceValue] = useState(false);
  const [isSetselectedDays, setIsSetselectedDays] = useState(false);
  const [tearmAndConditionClick, setTearmAndConditionClick] = useState(true);
  const [customerLeads, setCustomerLeads] = useState({
    name: "",
    email: "",
    phone: "",
    from: "Sign up page",
  });
  const [prevCustomerLeads, setPrevCustomerLeads] = useState({
    ...customerLeads,
  });
  const [daysError, setDaysError] = useState(false);

  const initialValues = {
    deliveryPlace: selectedOption,
    deliveryDays: [],
    city: "",
    master_location: "",
    address: "",
    landMark: "",
    buildingName: "",
    flatNo: "",
    preferred_delivery_time: "",
    area_time: "",
    currentWeight: "",
    height: "",
    goalWeight: "",
    activity_level: "",
    first_name: "",
    phone_number: "",
    last_name: "",
    password: "",
    email: "",
    repeat_password: "",
    acceptTearmAndCondition: false,
  };

  const fetchRegistrationData = () => {
    setLoader(true);
    request(
      Constant.URL.baseUrl +
        selectedLanguage +
        "/" +
        Constant.URL.RegisterPageData,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
        } else {
          if (result.status == 200) {
            setCities2(result.data.data.cities);
            setAddressCategories(result.data.data.address_category);
            setPreferredDeliveriedTime(result.data.data["delivery-time"]);
            setActivityLevel(result.data.data.activity_level);
            setHeight(result.data.data.height);
            setGoalWeight(result.data.data.goal_weight);
            setCurrentWeight(result.data.data.weight);
            let areaTime1 = [];
            result.data.data["delivery-time"].forEach((element, key) => {
              element["area-timing"].forEach((element1, key1) => {
                areaTime1.push({
                  name: element1.name,
                  id: element1.id,
                  index: key1,
                });
              });
              setAreaTime(areaTime1);
            });
          } else {
          }
        }
      }
    );
  };
  const openTermsAndConditions = () => {
    window.open("/terms-conditions");
  };

  useEffect(() => {
    fetchRegistrationData();
  }, []);

  const validationSchemaStep1 = yup.object({
    city: yup
      .string()
      .required("City is required")
      .oneOf(
        cities2.map((level) => level.name),
        "Invalid Entry"
      ),
    master_location: yup
      .string()
      .required("Master Location is required")
      .oneOf(
        masterLocations.map((level) => level.name),
        "Invalid Entry"
      ),
    address: yup.string().required(<Language>address_is_required</Language>),
    landMark: yup.string().required("Landmark is required"),
    buildingName: yup.string().required("Building Name is required"),
    flatNo: yup
      .string()
      .required("Flat Number is required")
      .matches(/^[0-9]+$/, "Only contain numbers"),
    preferred_delivery_time: yup
      .string()
      .required("Preferred Delivery Time is required")
      .oneOf(
        preferredDeliveriedTime.map((level) => level.name),
        "Invalid Entry"
      ),
    area_time: yup
      .string()
      .required("Area Time is required")
      .oneOf(
        areaTime.map((level) => level.name),
        "Invalid Entry"
      ),
  });

  const validationSchemaStep2 = yup.object({
    currentWeight: yup
      .string()
      .required("Current Weight is required")
      .oneOf(
        currentWeight.map((level) => level.name),
        "Invalid Current Weight Level"
      ),
    height: yup
      .string()
      .required("Height is required")
      .oneOf(
        height.map((level) => level.name),
        "Invalid height Level"
      ),
    goalWeight: yup
      .string()
      .required("Goal Weight is required")
      .oneOf(
        goalWeight.map((level) => level.name),
        "Invalid Goal Weight Level"
      ),
    activity_level: yup
      .string()
      .required("Exercise Level is required")
      .oneOf(
        activityLevels.map((level) => level.name),
        "Invalid Exercise Level"
      ),
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validationSchemaStep3 = yup.object({
    first_name: yup.string().required("First Name is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .min(7, "Phone number criteria: Minimum - 7 digits, Maximum - 12 digits")
      .max(12, "Phone number criteria: Minimum - 7 digits, Maximum - 12 digits")
      .matches(/^[0-9]+$/, "Phone number can only contain numbers"),
    last_name: yup.string().required("Last Name is required"),
    password: yup.string().required("Password is required"),
    email: yup
      .string()
      .matches(emailRegex, "Invalid email")
      .required("Email is required"),
    repeat_password: yup
      .string()
      .required("Repeat password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const onSubmitHandler = (values) => {
    const params = {
      fname: values.first_name,
      lname: values.last_name,
      email: values.email,
      password: values.password,
      phone: values.phone_number,
      address: {
        category: Number(selectedOption),
        address1: Number(values.flatNo),
        address2: values.buildingName,
        street: values.address,
        city: Number(values.city_id),
        state: Number(values.masterLocation),
        comment: values.landMark,
        delivery_time: values.preferred_delivery_time,
        area_timing: values.area_time,
        geolocation: latLng,
        delivery_days: selectedDays.join(","),
      },
      customerDetails: {
        weight: values.currentWeight,
        height: values.height,
        weight_goals: values.goalWeight,
        activity_level: values.activity_level,
      },
    };
    setLoader(true);
    request(Constant.URL.registrations, "post", params, "", (error, result) => {
      setLoader(false);
      if (error) {
        setApiError(error?.response?.data?.message);
      } else {
        if (result?.status == 200) {
          navigate("/");
          localStorage.setItem("selectedLanguage", selectedLanguage);
          localStorage.setItem("token", result?.data?.token);
        } else if (result.status == 401) {
          setApiError(result?.data?.message);
        } else {
          setApiError(result?.response);
        }
      }
    });
  };

  const handleDayChange = (event, value) => {
    // setDaysError(false);
    if (!selectedDays.includes(value)) {
      setSelectedDays([...selectedDays, value]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== value));
    }
  };
  localStorage.setItem("deliveryDays", selectedDays);
  localStorage.setItem("deliveryPlace", selectedOption);
  localStorage.setItem("acceptTearmAndCondition", acceptTearmAndCondition);
  localStorage.setItem("checkDeliveryDaysError", false);

  const handleDataFromChild = (data) => {
    if (data !== null || data !== "") {
      let masterLocation1 = [];
      cities2
        .find((c) => c.name === data)
        ?.areas?.forEach((element, key) => {
          masterLocation1.push({ name: element.name, id: element.id });
        });
      setMasterLocations(masterLocation1);
    }
  };

  const handelAcceptTearmAndCondition = (props) => {
    setacceptTearmAndCondition(props.target.checked);
    setTearmAndConditionClick(true);
  };

  const handlesetStepStats = (data) => {
    if (Object.keys(data)[0] == "is_set_delivery_place_value") {
      setIsSetDeliveryPlaceValue(data.is_set_delivery_place_value);
    }

    if (Object.keys(data)[0] == "is_set_delivery_days_value") {
      setIsSetselectedDays(data.is_set_delivery_days_value);
    }

    if (Object.keys(data)[0] == "is_accept_tearm_and_condition") {
      setTearmAndConditionClick(data.is_accept_tearm_and_condition);
    }
  };

  useEffect(() => {
    const isValidated = validatecustomerLeads(customerLeads, prevCustomerLeads);
    if (isValidated) {
      request(
        Constant.URL.baseUrl + Constant.URL.SavePipeDrive,
        "post",
        customerLeads,
        "",
        (error, result) => {
          if (error) {
            console.log("error", error);
          }
        }
      );
      setPrevCustomerLeads({ ...customerLeads });
    }
  }, [customerLeads, prevCustomerLeads]);

  useEffect(() => {
    if (selectedDays.length > 0 && selectedDays.length < 5) {
      setDaysError(true);
    } else {
      setDaysError(false)
    }
  }, [selectedDays])

  return (
    <div className="registration-content">
      <Loader isLoading={loader} />
      <div className="profile-wapper">
        <div className="form-body mt-8">
          {cities2.length > 0 ? (
            <MultiStepForm
              initialValues={initialValues}
              onSubmit={(values) => {
                if (
                  acceptTearmAndCondition == false ||
                  tearmAndConditionClick == ""
                ) {
                  localStorage.setItem("next", false);
                  setTearmAndConditionClick(false);
                  return;
                }
                onSubmitHandler(values);
              }}
              setStepStats={handlesetStepStats}
              daysError={daysError}
            >
              <FormStep
                onSumbit={() => null}
                validationSchema={validationSchemaStep1}
              >
                <Grid container>
                  <Grid sm={7}>
                    <div className="mb-3 place-details">
                      <p className="label">
                        <Language>
                          where_would_you_like_your_meals_delivered
                        </Language>
                      </p>
                      <div className="radio-wrapper">
                        {addressCategories.map((option) => (
                          <div className="flex items-center">
                            <label
                              key={option.id}
                              className="radio-label flex items-center justify-start flex-row"
                            >
                              <input
                                name="deliveryPlace"
                                type="radio"
                                value={option.id}
                                checked={selectedOption === option.id}
                                onChange={(event) =>
                                  setSelectedOption(event.target.value)
                                }
                                className="me-2 radio-input"
                              />
                              {option.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {isSetDeliveryPlaceValue ? (
                      <div style={{ color: "#d32f2f", fontSize: 12 }}>
                        {selectedOption ? "" : "Delivery place is required"}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="my-3">
                      <p className="label">
                        <Language>
                          which_days_would_you_like_to_receive_your_meals
                        </Language>
                      </p>
                      <div className="flex flex-wrap mt-8 md:mt -7">
                        {mealsDays?.map((day) => (
                          <div className="mx-2 mb-6 xl:mb-0" key={day.value}>
                            <label
                              htmlFor=""
                              className={`week_days ${
                                selectedDays.includes(day.value) ? "active" : ""
                              }`}
                              onClick={(event) =>
                                handleDayChange(event, day.value)
                              }
                            >
                              {day.label}
                            </label>
                          </div>
                        ))}
                      </div>
                        {daysError && (
                          <div className="text-red-700 text-xs px-2 mt-4">
                            Please select a minimum of 5 days
                          </div>
                        )}
                      <div>
                      </div>
                    </div>
                    {isSetselectedDays ? (
                      <div style={{ color: "#d32f2f", fontSize: 12 }}>
                        {selectedDays.length > 0
                          ? ""
                          : "Please select at least one day"}
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="step-one-box">
                      <Grid container columnSpacing={5} className="">
                        <Grid item xs={12} sm={12} md={6}>
                          <div className="mt-2">
                            <SelectInput
                              name="city"
                              label="city"
                              options={cities2}
                              placeholder="city"
                              localKeyName={"city_id"}
                              sendDataToParent={handleDataFromChild}
                            />
                          </div>
                          <div className="mt-2">
                            <SelectInput
                              name="master_location"
                              label="master_location"
                              options={masterLocations}
                              localKeyName={"masterLocation"}
                              placeholder="master_location"
                            />
                          </div>
                          <div className="mt-2">
                            <InputField
                              name="address"
                              label="street_name_and_number"
                            />
                          </div>
                          <div className="mt-2">
                            <InputField
                              name="landMark"
                              label="landmark_to_help_locate_you"
                            />
                          </div>
                          <div className="mt-2">
                            <InputField
                              name="buildingName"
                              label="building_name"
                            />
                          </div>
                          <div className="mt-2">
                            <InputField
                              type="number"
                              label="villa_flat_number"
                              name="flatNo"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={5}>
                          <div className=" mt-2 lg:mr-2">
                            <SelectInput
                              name="preferred_delivery_time"
                              label="address"
                              placeholder="preferred_delivery_time"
                              options={preferredDeliveriedTime}
                              localKeyName={"PreferredDeliveryTime"}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <div className="mt-2">
                            <SelectInput
                              name="area_time"
                              label="address"
                              placeholder="area"
                              options={areaTime}
                              localKeyName={"areaTime"}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                  <Grid sm={5} className="mobile-map-hide mt-8">
                    <div
                      onClick={() => setLatLng(localStorage.getItem("latLng"))}
                    >
                      Map
                      <Map />
                    </div>
                  </Grid>
                </Grid>
              </FormStep>

              {/* step 2 */}
              <FormStep
                onSumbit={() => null}
                validationSchema={validationSchemaStep2}
                className="test"
              >
                <div className="personal-status-wapper">
                  <div className="form-body">
                    <Grid
                      container
                      spacing={1}
                      display={"flex"}
                      direction={"column"}
                      justifyContent={"center"}
                    >
                      <Grid xs={12}>
                        <h2 className="section-title text-center md:mt-6">
                          <Language>what's_your_personal_stats</Language>
                        </h2>
                      </Grid>
                      <div className="flex justify-center mt-9">
                        <Grid xs={12} sm={6} md={6} className="step-one-box ">
                          <Grid item xs={12}>
                            <div className="my-2">
                              <SelectInput
                                name="currentWeight"
                                options={currentWeight}
                                localKeyName={"currentWeight"}
                                placeholder="current_weight"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="my-2">
                              <SelectInput
                                name="goalWeight"
                                options={goalWeight}
                                localKeyName={"goalWeight"}
                                placeholder="goal_weight"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="my-2">
                              <SelectInput
                                name="height"
                                options={height}
                                localKeyName={"height"}
                                placeholder="height"
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <div className="my-2">
                              <SelectInput
                                name="activity_level"
                                options={activityLevels}
                                localKeyName={"experienceLevel"}
                                placeholder="exercise_level"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </div>
                </div>
              </FormStep>
              {/* {step 3} */}
              <FormStep
                onSumbit={() => null}
                validationSchema={validationSchemaStep3}
              >
                <div className="registration-form-wapper">
                  <div className="form-body">
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={2}
                      className="step-one-box"
                    >
                      <Grid xs={12} className="mb-7 md:mb-9">
                        <h2 className="section-title text-center px-8 md:px-0 md:mt-6">
                          <Language>last_step_and_we're_good_to_go</Language>
                        </h2>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={6}
                        md={6}
                        className="step-three-left-box px-2"
                      >
                        <Grid item xs={12}>
                          <div>
                            <InputField
                              name="first_name"
                              label="first_name"
                              onBlur={(e) =>
                                setCustomerLeads((prevCustomerLeads) => ({
                                  ...prevCustomerLeads,
                                  name: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="my-2">
                            <InputField name="last_name" label="last_name" />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="my-2">
                            <InputField
                              type="email"
                              name="email"
                              label="email"
                              onBlur={(e) =>
                                setCustomerLeads((prevCustomerLeads) => ({
                                  ...prevCustomerLeads,
                                  email: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={6}
                        md={6}
                        className="step-three-right-box px-2"
                      >
                        <Grid item xs={12}>
                          <div>
                            <InputField
                              type="number"
                              name="phone_number"
                              label="phone_number"
                              onBlur={(e) =>
                                setCustomerLeads((prevCustomerLeads) => ({
                                  ...prevCustomerLeads,
                                  phone: e.target.value,
                                }))
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="my-2">
                            <InputField
                              type="password"
                              name="password"
                              label="create_password"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="my-2">
                            <InputField
                              type="password"
                              name="repeat_password"
                              label="repeat_password"
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="custom-radio flex flex-col justify-center items-center">
                          <div className="place-details radio-wrapper">
                            {aggrement.map((option) => (
                              <div className="flex">
                                <label
                                  key={option.value}
                                  className="radio-label flex items-center justify-start flex-row"
                                >
                                  <input
                                    name="acceptTearmAndCondition"
                                    type="radio"
                                    value={option.label}
                                    checked={
                                      acceptTearmAndCondition === option.value
                                    }
                                    onChange={handelAcceptTearmAndCondition}
                                    className="me-2 radio-input"
                                  />
                                  {option.label}
                                </label>
                              </div>
                            ))}
                            <Link
                              onClick={openTermsAndConditions}
                              style={{
                                textDecoration: "underline",
                                color: "#1B4633",
                              }}
                            >
                              Terms and Conditions
                            </Link>
                          </div>
                          {tearmAndConditionClick == false ? (
                            <div style={{ color: "#d32f2f", fontSize: 12 }}>
                              Please accept term and condition
                            </div>
                          ) : (
                            ""
                          )}
                          <p
                            style={{
                              color: "#d32f2f",
                              fontSize: 15,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {apiError ? apiError : ""}
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </FormStep>
            </MultiStepForm>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default DemoRegistration;
