import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Language = (props) => {
    const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return localStorage.getItem('selectedLanguage') || 'en';
      });
    
    useEffect(() => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
      }, [selectedLanguage, i18n]);
      
    return t(props.children);
}

export default Language