import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";

export default function Blog2() {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const [value, setValue] = useState(null);
  const [blogByTagData, setBlogByTagData] = useState([]);
  const [tabUrl, setTabUrl] = useState();
  const [loader, setLoader] = useState(false);
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeTabs = (url) =>
  {
    navigate(`/blog/${url}`);
    setTabUrl(url);
  };

  useEffect(() => {
    if (id) 
    {
      setTabUrl(id);
    }
    else
    {
      setTabUrl(null);
    }
  }, [location]);

  const blogsByTag = () =>
  {
    setLoader(true);
    let blogUrl = Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.BlogByTag
    request(
      tabUrl ? (blogUrl + '/'+tabUrl) : blogUrl,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
          console.log('if error', error);
        } else {
          if (result.status == 200) {
            setBlogByTagData(result.data.data);
          } else {
            console.log('result else', result);
          }
        }
      }
    );
  }

  useEffect(() => {
    blogsByTag();
  }, [tabUrl]);

  useEffect(() => {
    let tempIndex = '';
    blogByTagData?.tags?.forEach((x, i) =>
    {
      if (x.id === id)
      {
        return tempIndex = i;
      }
    });
    setValue(tempIndex);
  }, [blogByTagData]);

  return (
    <div className="blog-main">
      <Loader isLoading={loader}/>
      <div className="custom-container">
        <h2 className="section-title"><Language>blog</Language></h2>
        <Grid container className="mt-10 custom-tab-blog md:px-16">
          <Grid xs={12} className="flex justify-center items-center">
          <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
            {
              blogByTagData?.tags?.map((x, index) => 
              <Tab label={x.title} key={index} onClick={() => handleChangeTabs(x.url)} />
              )
            }
            </Tabs>
          </Grid>
          <Grid xs={12} className="mt-5 mb-10">
              <Grid container>
              {blogByTagData?.blogs?.length > 0 ? (
                blogByTagData.blogs.map((item, index) => (
                  <Grid xs={12} sm={6} md={4} key={index}>
                    <div
                      className="blog-parent-box"
                      onClick={() =>
                        navigate(`/blog-detail2/${item.tags?.map(x => x.id)}/${item.id}`, {
                          state: { blogId: item.id }
                        })
                      }
                    >
                      <div className="blog-img mb-5">
                        <img src={item.thumbnail} alt="img" className="w-full h-full" />
                      </div>
                      <p className="blog-title">{item.title}</p>
                    </div>
                  </Grid>
                ))
              ) : (
                <Grid xs={12}>
                  <h2 className="text-center" style={{ fontSize: 24 }}>No data found</h2>
                </Grid>
              )}
              </Grid>
              <Grid container>
                <Grid
                  xs={12}
                  className="flex justify-center items-center blog-page mt-4"
                >
                  <Stack spacing={2}>
                    <Pagination
                      hideNextButton
                      hidePrevButton
                      count={blogByTagData?.blogs?.length > 0 ? blogByTagData?.no_of_pages: ""}
                      shape="rounded"
                    />
                  </Stack>
                </Grid>
              </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
