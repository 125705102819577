import React, {useEffect, useState,} from "react";
import { Rating, useMediaQuery } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CallIcon from "@mui/icons-material/Call";
import EastIcon from "@mui/icons-material/East";
import Slider from "react-slick";
import Plan from "../../components/home/Plan";
import Listitemplan from "../../assets/images/list-item.png";
import FAQs from "../../components/faqs/FAQs";
import Review from "../../components/home/review";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";

const Home = () => {
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 426px)");
  const [reviews, setReviews] = useState([]);
  const [whatsAppUrl, setWhatsAppUrl] = useState('');
  const [mealPlanData, setMealPlanData] = useState([]);
  const [loader, setLoader] = useState(false);
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const fetchReviewsData = () => {
    setLoader(true);
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.Home,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
        } else {
          if (result.status == 200) {
            setReviews(result.data.data.reviews);
            setWhatsAppUrl(result.data.data.whatsapp_url);
          } else {
          }
        }
      }
    );
  };

  const fetchMealPlanData = () => {
    setLoader(true);
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.MealPlan,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
        } else {
          if (result.status == 200) {
            setMealPlanData(result.data.data);
          } else {
          }
        }
      }
    );
  }

  useEffect(() => {
    fetchReviewsData();
    fetchMealPlanData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: false,
    className: "reviews-carousel",
  };
  const mealplan = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: false,
    className: "mealplan-carousel",
  };
  return (
    <div className="home-wrapper">
      <Loader isLoading={loader}/>
      <div className="md:block hidden">
        <div className="text-center mb-8">
       {Object.keys(mealPlanData).map((category, index) => (
        <div key={index}>
         <h3 className="section-title mt-10 mb-7">{category}</h3>
          <div className="home-plans">
            {mealPlanData[category].map((meal, index) => (
                <Plan key={index} plan={meal} plans={mealPlanData} />
                ))}
          </div>
        </div>
      ))}
        </div>
      </div>
      <div className="md:hidden block mobile-meal-plan">
        <div className="text-center mb-8">
              {Object.keys(mealPlanData).map((category, index) => (
                <div key={index}>
                <h3 className="section-title mt-10 mb-7">{category}</h3>
                <div className="custom-container pb-8 mobile-plan-box">
                  <Slider {...mealplan}>
                    {mealPlanData[category].map((meal, index) => (
                        <Plan key={index} plan={meal} plans={mealPlanData} />
                        ))}
                  </Slider>        
                  </div>
                </div>
              ))}
        </div>
      </div>
      <div className="w-full section-choose-plan">
      <div className="grid md:grid-cols-2  talk-to-professional custom-container py-10 md:py-16">
    
        <div className=" talk-to-professional-right-content">
              <h3 className="talk-to-professional-title text-center md:text-start mb-8"><Language>need_help_choosing_a_plan</Language></h3>
              <p className="plan-list-item text-center md:text-start mb-5"><Language>our_nutritionists_are_here_to_help_get_in_touch_to_find_your_fit</Language></p>
              <div className="talk-to-professional-left-image md:hidden block my-7">
                <img src={Constant.URL.ImageUrl + '/images/plansGif.gif'} alt="" className="plan-img w-full h-auto object-contain" />
              </div>
              <ul className="list-none mb-10">
                  <li className="flex flex-row items-center mb-5">
                    <img src={Listitemplan} width={20} height={20}/>
                    <p className="plan-list-item ml-3"><Language>answer_questions_you_may_have_about_your_nutrition_needs</Language></p>
                  </li>
                  <li className="flex flex-row items-center mb-5">
                    <img src={Listitemplan} width={20} height={20}/>
                    <p className="plan-list-item ml-3"><Language>Get_answers_on_how_you_can_reach_your_goals_faster</Language></p>
                  </li>
                  <li className="flex flex-row items-center mb-5">
                    <img src={Listitemplan} width={20} height={20}/>
                    <p className="plan-list-item ml-3"><Language>Understand_more_how_our_service_can_help_you</Language></p>
                  </li>
              </ul>
          <div className="flex justify-center md:justify-start ">
          <button className="talk-to-professional-button">
            <CallIcon />
            <a href={whatsAppUrl} target="_blank"><span className="mx-1">Whatsapp now</span></a>
          </button>
          </div>
        </div>
        <div className="talk-to-professional-left-image md:block hidden">
         <img src={Constant.URL.ImageUrl + '/images/plansGif.gif'} alt="" className="plan-img w-full h-auto object-contain" />
        </div>
      </div>
      </div>
      <div className="mt-16 reviews">
        <div className="text-center mb-8">
         <h3 className="section-title"><Language>customer_reviews</Language></h3>
        </div>

        <div className="custom-container">
          <Slider {...settings}>
            {reviews.map((plan, index) => (
              <Review key={index} plan={plan} />
            ))}
          </Slider>
        </div>
     
      </div>
    <div className="w-full section-choose-plan">
      <div className="grid md:grid-cols-2  talk-to-professional custom-container py-8 md:py-16">
        <div className="your-plan-right md:block hidden">
          <img src={Constant.URL.ImageUrl  + '/images/your plan.png'}alt="" className="plan-img" />
        </div>
        <div className=" talk-to-professional-right-content text-center md:text-start md:pl-14">
              <h3 className="talk-to-professional-title mb-8"><Language>need_to_talk_to_a_professional</Language></h3>
              <p className="plan-list-item mb-10"><Language>get_in_touch_with_a_nutrition_advisor_to_answer_all_your_questions</Language></p>
          <button className="talk-to-professional-button">
            <CallIcon />
            <a href={whatsAppUrl} target="_blank">
            <span className="mx-1" >Whatsapp now</span>
            </a>
          </button>
        </div>
      
      </div>
      </div>
      <div className="w-full section-faq">
        <FAQs />
      </div>
    </div>
  );
};

export default Home;
