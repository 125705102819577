import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { store } from "./store/store";
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import en from './common/languages/en.json';
import ar from './common/languages/ar.json';

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      translation: en,
    },
    ar: {
      translation: ar,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
    <I18nextProvider i18n={i18next}>
      <App />
      </I18nextProvider>
    </BrowserRouter>
  </Provider>
);
