import React, { useState } from "react";
import loginImage from "../../assets/images/your plan.png";
import { Container, Grid} from "@mui/material";
import TextInputField from "../../common/inputFields/TextInputField";
import { useNavigate } from "react-router-dom";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import { values } from "lodash";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [apiError, setApiError] = useState('');
  const [loader, setLoader] = useState(false);

  const handleSubmit = (values) => {
    const params = {
      'email': values.email,
      'password': values.password,
    }
    setLoader(true);
    request(
      Constant.URL.Login,
      "post",
      params,
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
         setApiError(error.response.data.message);
        } else {
          if (result.status == 200) {
            navigate('/');
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("name", result.data.data.name);
            localStorage.setItem("profileimg", result.data.data.profile_pic);
            localStorage.setItem("phone", result.data.data.phone);
            localStorage.setItem("email", result.data.data.email);
            window.location.reload();
          } else if (result.status == 401) {
            setApiError(result.data.message);
          } else {
            setApiError(result.response.data.message);
          }
        }
      }
    );
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  return (
    <div className="login-wrapper">
      <Loader isLoading={loader}/>
      <Container className="custom-container">
        <div className="grid grid-cols-1 md:grid-cols-2" >
          <div className="justify-start items-center hidden md:flex">
                <div className="login-image">
                  <img src={loginImage} alt="" className="w-full h-full" />
                </div>
          </div>
          <div>
            <div className="login-right">
              <div className="login-details xl:mr-64">
                <h2 className="welcome-text text-center mb-4">
                  <Language>welcome_back</Language>
                </h2>
                <Formik
                       initialValues={initialValues}
                       validationSchema={validationSchema}
                       onSubmit={handleSubmit}>
                       {({handleChange, errors, handleSubmit  }) => (
                         <Form>
                            <TextInputField
                              name="email"
                              type="email"
                              wrapperClassName="w-full mb-0"
                              inputClassName="form-control"
                              placeholder="email"
                              value={values.email}
                              onChange={handleChange('email')}
                               />
                            <div style={{color: 'red', fontSize: 12}}>{errors.email}</div>
                            <TextInputField
                              name="password"
                              type="password"
                              wrapperClassName="w-full mb-0"
                              inputClassName="form-control"
                              placeholder="password"
                              value={values.password}
                              onChange={handleChange('password')}
                            />
                            <div style={{color: 'red', fontSize: 12}}>{errors.password}</div>
                            <button
                             className="w-full py-2 btn-success"
                             type="submit"
                             onClick={handleSubmit}
                            >
                             <Language>log_in</Language></button>
                         </Form>
                       )}
                     </Formik>
                    <div style={{color: 'red', fontSize: 15, display: 'flex', justifyContent: 'center'}}>{apiError ? apiError : null}</div>
                   <div className="buttons">
                     <button
                       className="w-full mt-2 py-2 btn-forgot text-center"
                       type="button"
                       onClick={() => navigate("/reset-password")}
                     >
                       <Language>forgot_password</Language>
                     </button>
                   </div>
              </div>
            </div>
          </div>
        </div>
        </Container>
    </div>
  );
};

export default Login;
