import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {  NavLink, useNavigate } from "react-router-dom";
import LOGO from "../../assets/images/logo.png";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import CloseIcon from '@mui/icons-material/Close';
import Language from "../../common/Language";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {removeItemFromLocalStorage} from "../../utils/localStorage"

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [setlang, updatelang] = useState(null);
  const [button, setButton] = useState();
  const [buttonhide, setButtonhide] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const hidemobilenavbar = () => {
    setIsVisible(false)
  }
  function languageSettings(language) {
    return function() {
      localStorage.setItem('selectedLanguage', language);
      window.location.reload();
    };
  }
  useEffect(() => 
  {
    const token = localStorage.getItem("token");
    if (token)
    {
      request(Constant.URL.UserData, "get", "" ,token, (error, result) => {
        if (error) {
          setButtonhide(false)
          setButton(<Language>log_in</Language>);
        } else {
          if (result.data.status == 401) {
            setButtonhide(false)
            setButton(<Language>log_in</Language>);
          } else if (result.status == 200) {        
            setUserDetails(result.data.data);
            setButtonhide(true);
          } else {
            setButtonhide(false)
            setButton(<Language>log_in</Language>);
          }
        }
      });
      // setButtonhide(true);
      // setName(localStorage.getItem("name"))
      // setProfilePic(localStorage.getItem("profileimg"))
    }
    else
    {
      setButton(<Language>log_in</Language>);
    }
  }, []);

  const open = Boolean(anchorEl);
  const openlang = Boolean(setlang);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClicklang = (event) => {
    updatelang(event.currentTarget);
  };
  const handleCloselang = () => {
    updatelang(null);
  };
  const howItWorks = [
    {
      name: "Personalize meal plan",
      onClick: () => {
        handleClose();
        navigate("/personalize-meal-plan");
      },
    },
    {
      name: "Registration",
      onClick: () => {
        handleClose();
        navigate("/registration");
      },
    },
  ];

  const onChangeHandler = () =>
  {
    setIsVisible(false)
    const token = localStorage.getItem("token");
    if(token)
    {
      request(Constant.URL.Logout, "get", "", token, (error, result) => {
        if (error) {
        } else {
          if (result.status == 200) {
            setButton(<Language>log_in</Language>);
            navigate('/login');
            removeItemFromLocalStorage('token');
            removeItemFromLocalStorage('checkoutPageData')
          } else if (result.status == 401) {
          } else {
          }
        }
      });
    }
    else {
      removeItemFromLocalStorage('checkoutPageData')
      navigate('/login')
    }
  }

  const languageChange = [
    {
      name: "EN",
      value: "en",
    },
    {
      name: "AR",
      value: "ar",
    },
  ];
  const profile = [
    {
      name: "My Profile",
    },
    {
      name: "Logout",
    }
  ]
  const profilePage = () =>
  {
    setIsVisible(false)
    navigate('/profile')
  }
  return (
    <>
    <div className="sticky top-0 w-full z-50 navbar-wrapper">
      <div className={` flex justify-between items-center md:my-5 mx-auto custom-container ${isVisible ? 'mobile-navbar' : 'hidden-menu'}`}>
      <div className="md:hidden block mobile-login-btn">
      {!buttonhide &&
        <div
            className="px-3 py-2 cursor-pointer login-btn "
            aria-controls={openlang ? "lang-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openlang ? "true" : undefined}
            onClick={() => onChangeHandler()}
          >
            {button}
          </div>
      }
      {buttonhide &&   
         <div className="">
              <div style={{display:"flex",cursor:"pointer"}} onClick={() => profilePage()} >
                <img src={userDetails?.profile_pic} alt="" srcset=""  style={{width:"25px", height:"25px", borderRadius:"50%"}}/>
                <span className="ml-2" style={{color:"#fff"}}>{userDetails?.name}</span>
              </div>
          </div> 
            }
      
        </div>
        <div className="logo-desktop hidden md:flex flex items-center flex-col md:flex-row  pt-16 md:pt-0 " onClick={() => removeItemFromLocalStorage("checkoutPageData")}>
        <NavLink to="/">
              <img src={LOGO} alt="logo" className="cursor-pointer"/>
            </NavLink>
        </div>
        <div className="flex items-center flex-col md:flex-row mobile-menu pt-16 md:pt-0">
        <NavLink onClick={hidemobilenavbar} activeClassName="active" className="md:ml-4 mb-8 md:mb-0 cursor-pointer navbar-item" to="/"><div onClick={() => removeItemFromLocalStorage("checkoutPageData")}><Language>plans</Language></div></NavLink>
          {/* <NavLink onClick={hidemobilenavbar} activeClassName="active" className="md:ml-8 md:hidden mb-8 md:mb-0 block cursor-pointer navbar-item" to="/">Home</NavLink> */}
          <div className="cursor-pointer mb-8 md:mb-0" onClick={() => removeItemFromLocalStorage("checkoutPageData")}>
            <NavLink
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              to="/how-it-works"
              className="navbar-item md:ml-4 lg:ml-8 "
              activeClassName="active"
              onClick={hidemobilenavbar}
            >
             <Language>how_it_works</Language>
            </NavLink>
          </div>
          <NavLink onClick={hidemobilenavbar} activeClassName="active" className="md:ml-4 lg:ml-8 mb-8 md:mb-0 cursor-pointer navbar-item" to="/contact-us"><div onClick={() => removeItemFromLocalStorage("checkoutPageData")}><Language>contact_us</Language></div></NavLink>
          <NavLink onClick={() => (hidemobilenavbar(), removeItemFromLocalStorage("tabUrl"))} activeClassName="active" className="md:ml-4 lg:ml-8 mb-8 md:mb-0 cursor-pointer navbar-item" to="/blog"><div onClick={() => removeItemFromLocalStorage("checkoutPageData")}><Language>blog</Language></div></NavLink>
          <NavLink onClick={hidemobilenavbar} hidden={true} activeClassName="active" className="md:ml-4 lg:ml-8 mb-8 md:mb-0 cursor-pointer navbar-item" to="/blog2"><div onClick={() => removeItemFromLocalStorage("checkoutPageData")}>Blog 2</div></NavLink>
        </div>
      
        <div className="flex items-center mobile-header">
          <div className="md:mr-8 cursor-pointer">
            <div className="navbar-item" 
              id="lang-button"
              aria-controls={openlang ? "lang-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openlang ? "true" : undefined}
              onClick={handleClicklang}
              > {localStorage.getItem("selectedLanguage")} <KeyboardArrowDownIcon /></div>
            <Menu
              id="lang-menu"
              anchorEl={setlang}
              open={openlang}
              onClose={handleCloselang}
              MenuListProps={{
                "aria-labelledby": "lang-button",
              }}
              className="custom-dropdown"
            >
              {languageChange.map((item) => (
                <MenuItem onClick={languageSettings(item.value)} selected={localStorage.getItem("selectedLanguage") === item.value}>{item.name}</MenuItem>
              ))}
            </Menu>
          </div>
          <span className="md:hidden inline close-icon">
             <CloseIcon style={{color:'white',fontSize:50}} onClick={toggleVisibility} />  
           </span>   
         {!buttonhide && <div
            className="px-3 py-2 cursor-pointer login-btn md:block hidden"
            aria-controls={openlang ? "lang-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openlang ? "true" : undefined}
            onClick={() => onChangeHandler()}
          >
            {button}
          </div>
          }
       {buttonhide &&   
         <div className="hidden md:flex">
              <div style={{display:"flex",cursor:"pointer"}} onClick={() => profilePage()} >
                <img src={userDetails?.profile_pic} alt="" srcset=""  style={{width:"25px", height:"25px", borderRadius:"50%"}}/>
                <span className="ml-2">{userDetails?.name}</span>
              </div>
          </div> 
            }
        </div>
      </div>
      <div className="custom-container flex flex-row justify-between items-center menu-mobile-header">
      <div className="logo md:hidden">
            <NavLink to="/">
              <img src={LOGO} alt="logo" className="cursor-pointer"/>
            </NavLink>
          </div>
          <span className="md:hidden inline">
            <DragHandleIcon className="mobile-menu-icon" onClick={toggleVisibility} />
          </span>
        </div>
    </div>
    </>
  );
};

export default Navbar;
