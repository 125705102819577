import React from "react";
import LOGO from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Countdown from "../../common/CountDown/CountDown";
import Language from "../../common/Language";

function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={LOGO} alt="Dummy Logo" className="w-48 h-36 mb-4" />
      <div className="text-6xl text-red-500 mb-2">404</div>
      <h2 className="text-2xl text-red-500 mb-2">
        <Language>not_found</Language>
      </h2>
      <p className="text-gray-700">
        <Language>page_does_not_exist</Language>
      </p>
      <p className="text-gray-700 mt-10">
        <Language>back_to</Language>{" "}
        <Link to="/" className="text-blue-500 underline">
          <Language>home</Language>
        </Link>
      </p>
      <Countdown initialCount={3} onZero={() => navigate("/")}>
        {(count) => (
          <p className="text-gray-700">
            {count !== 0 && (
              <p style={{ fontWeight: "bold" }} className="text-center">
                ({count})
              </p>
            )}
          </p>
        )}
      </Countdown>
    </div>
  );
}

export default NotFoundPage;
