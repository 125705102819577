import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";
import Slider from "react-slick";
import Language from "../../common/Language";


const BlogDetails2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [blogDetail, setBlogDetail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [blogId, setBlogId] = useState(location.state ? location.state.blogId : '');

  const selectedLanguage = localStorage.getItem('selectedLanguage');
  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMobile(window.innerWidth < 767.98);
    };
    window.addEventListener('resize', updateWindowDimensions);
    updateWindowDimensions();
    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []); 
  const mobileblog = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: false,
    className: "blogdetail-carousel",
  };
  const MobileViewSlider = () => {
    return (
      <>
      <Slider {...mobileblog}>
      {
        blogDetail.related_blogs?.map((x) =>
        <Grid xs={12} sm={6} md={4}>
        <div className="blog-parent-box mb-7" onClick={() => (navigate(`/blog-detail/${x.tags?.map(x => x.id)}/${x.id}`), setBlogId(x.id))}>
          <div className="blog-img mb-5">
            <img src={x.thumbnail} alt="img" className="w-full h-full" />
          </div>
          <p className="blog-title">
            {x.title}
          </p>
        </div>
      </Grid>
        )
      }
      </Slider>
      
      </>
    );
  };

  const fetchBlogDetail = () =>
  {
    setLoader(true);
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.BlogDetail + '/'+blogId,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
        } else {
          if (result.status == 200) {
            setBlogDetail(result.data.data);
          } else {
          }
        }
      }
    );
  }

  useEffect(() => {
    fetchBlogDetail();
  }, [blogId]);
  

  return (
    <>
      <div className="blogdetail-main">
      <Loader isLoading={loader}/>
        <div className="custom-container md:mb-10 mb-14">
          <Grid container className="md:px-24">
            <Grid xs={12}>
              <ul className="flex flex-row flex-wrap blog-header-detail md:mt-0">
                <li>
                  <a onClick={() => navigate('/blog2')}><Language>blog</Language></a>
                </li>
                <li>
                  <a>
                    <KeyboardArrowRightIcon />
                  </a>
                </li>
                {
                  blogDetail.tags?.map((tag, index) =>
                  <li>
                  <a onClick={() => navigate(`/blog2/${tag.slug}`)}>{tag.tag}</a>
                   </li>
                  )
                }
                <li>
                  <a>
                    <KeyboardArrowRightIcon />
                  </a>
                </li>
                <li>
                  <a>{blogDetail.title}</a>
                </li>
              </ul>
            </Grid>
            <Grid xs={12} className="mt-4">
              <h2 className="blog-title mb-8">
                {blogDetail.title}
              </h2>
              <div className="blog-detail-img">
                <img src={blogDetail.thumbnail} alt="img" />
              </div>
              <div className="mt-8">
                <div dangerouslySetInnerHTML={{ __html: blogDetail.content }} />
              </div>
            </Grid>
          </Grid>
          {isMobile ? (
            <div className="my-10">
                <MobileViewSlider />
                </div>) :
           (<Grid container className="md:px-24 mt-8">
              {
                blogDetail.related_blogs?.map((x) =>
                <Grid xs={12} sm={6} md={4}>
                <div className="blog-parent-box mb-7" onClick={() => (navigate(`/blog-detail2/${x.tags?.map(x => x.id)}/${x.id}`), setBlogId(x.id))}>
                  <div className="blog-img mb-5">
                    <img src={x.thumbnail} alt="img" className="w-full h-full" />
                  </div>
                  <p className="blog-title">
                    {x.title}
                  </p>
                </div>
              </Grid>
                )
              }
             </Grid>)
            }
        </div>
      </div>
    </>
  );
};

export default BlogDetails2;
