import React, { useEffect, useState } from "react";
import { useFormik, useField, useFormikContext } from "formik";
import {
  OutlinedTextFieldProps,
  Autocomplete as AutoInput,
} from "@mui/material";
import InputField from "./InputField";
import { useTranslation } from 'react-i18next';

interface TextInputProps extends OutlinedTextFieldProps {
  name: string;
}

const SelectInput = ({ name, options = [], localKeyName, placeholder, sendDataToParent }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const formik = useFormik(field);
  const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return localStorage.getItem('selectedLanguage') || 'en';
      });
    
    useEffect(() => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
      }, [selectedLanguage, i18n]);

  const handleChange = (props) => {
    if (name === 'city')
    {
      sendDataToParent(props.target.value)
    }
    setFieldValue(name, props.target.value);
    options.map((option) => {
      if (option.name == props.target.value) {
        localStorage.setItem(localKeyName, option.id);
      }
    });
  };

  return (
    <AutoInput
      options={options}
      getOptionLabel={(option) => option.name}
      style={{ width: 300 }}
      defaultValue={formik.getFieldProps(meta.value) || null}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(props) => (
        <InputField
          fullWidth
          {...field}
          {...props}
          margin="normal"
          onSelect={handleChange}
          onChange={handleChange}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          placeholder={t(placeholder)}
        />
      )}
    />
  );
};

export default SelectInput;
