import axios from 'axios';

export async function request(url, type, params, token, callback) {
    if (type == 'get') {
        let headers={ 'Content-Type': 'text/plain', "Access-Control-Allow-Origin": "*"}
        if(token){
            headers["Authorization"]='Bearer '+token
         }
        axios.get(url, {
            params: params,
            headers: headers,
        }).then(function (response) {
            if (response) {
                callback(null, response)
            }
            else {
                let err = Error("No data found")
                callback(err, null)
            }
        }).catch(function (error) {
            if (error.response && error.response.data) {
                callback(error, null)
            }
            else {
                callback(error, null)
            }
        })
    }
    else if (type == 'post') {
        const options = {
            method: 'POST',
            headers: { 'Authorization': 'Bearer '+token, 'content-type': 'application/json', 'Accept': 'application/json' },
            data: JSON.stringify(params),
            url,
        };
        axios(options).then(function (response) {
            if (response) {
                callback(null, response)
            }
            else {
                let err = Error("No data found")
                callback(err, null)
            }
        }).catch(function (error) {
            if (error.response && error.response.data) {
                callback(error, null)
            }
            else {
                callback(error, null)
            }
        })
    }
    else if (type == 'post_multipart') {
        axios.post(url, params, {
            headers: { 'token': token, 'content-type': 'application/x-www-form-urlencoded' },
        }).then(function (response) {
            if (response.data) {
                callback(null, response.data)
            }
            else {
                let err = Error("No data found")
                callback(err, null)
            }
        }).catch(function (error) {
            if (error.response && error.response.data) {
                callback(error, null)
            }
            else {
                callback(error, null)
            }
        })

    }
}