import React, { useState } from "react";
import loginImage from "../../assets/images/your plan.png";
import { Container, Grid} from "@mui/material";
import TextInputField from "../../common/inputFields/TextInputField";
import { useNavigate } from "react-router-dom";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { values } from "lodash";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
  email: "",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const [apiError, setApiError] = useState('');
  const [loader, setLoader] = useState(false);

  const handleSubmit = (values) => {
    const params = {
      email: values.email,
    };
    setLoader(true);
    request(
      Constant.URL.ForgotPassword,
      "post",
      params,
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
          setApiError(error.response.data.message);
        } else {
          if (result.status == 200) {
            toast.success(
              `Successfully sent a password reset link to email ${values.email} `,
              {
                autoClose: 3000,
                onClose: () => {
                  navigate("/", {
                    state: { token: result.data.token },
                  });
                },
              }
            );
          } else if (result.status == 401) {
            setApiError(result.data.message);
          } else {
            setApiError(result.response.data.message);
          }
        }
      }
    );
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  return (
    <div className="login-wrapper">
      <ToastContainer />
      <Loader isLoading={loader}/>
      <Container className="custom-container">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div
            className=" justify-start items-center hidden md:flex"
          >
            <div className="login-image">
              <img src={loginImage} alt="" className="w-full h-full" />
            </div>
          </div>
          <div>
            <div className="login-right">
              <div className="login-details xl:mr-64">
                <h2 className="welcome-text text-center mb-4">
                  <Language>reset_password</Language>
                </h2>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleChange, errors, handleSubmit }) => (
                    <Form>
                      <TextInputField
                        name="email"
                        type="email"
                        wrapperClassName="w-full mb-0"
                        inputClassName="form-control"
                        placeholder="email"
                        value={values.email}
                        onChange={handleChange("email")}
                      />
                      <div style={{ color: "red", fontSize: 12}}>
                        {errors.email}
                      </div>
                      <button
                        className={`w-full py-2 btn-success ${errors.email ? 'mt-2' : ''}`}
                        type="submit"
                        onClick={handleSubmit}
                      >
                       <Language>change_password</Language>
                      </button>
                    </Form>
                  )}
                </Formik>
                <div style={{color: 'red', fontSize: 15, display: 'flex', justifyContent: 'center'}}>{apiError ? apiError : null}</div>
                <button
                  className="w-full mt-2 py-2 rounded-md btn-forgot"
                  type="button"
                  onClick={() => {
                    navigate("/register");
                  }}
                >
                  <Language>don't_have_an_account</Language>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
