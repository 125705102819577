import React from "react";
import google from "../../assets/images/google.png";

const Review = ({ plan }) => {
  return (
    <div className="home-review-wrapper mx-10">
      <div className="flex flex-col justify-center items-center ">
        {/* <div className="customer-img">
            <img src={plan.link} alt={''} className="w-full h-full" />
        </div> */}
        <div className="flex flex-col justify-center items-center mt-6">
            <p className="customer-desc">
                {plan.content}
            </p>
            {/* <p className="review-time">
                {plan.date}
            </p> */}
            {/* <img src={google} alt="" width={20} className="mt-5" /> */}
            <p className="customer-name mt-5">
                {plan.source}
            </p>
        </div>
      </div>
    </div>
  );
};

export default Review;
