import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import Slider from "react-slick";
import TalkToProfessional from "../../assets/images/howitwork.png";
import FAQs from "../../components/faqs/FAQs";
import Review from "../../components/home/review";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import EastIcon from "@mui/icons-material/East";
import Arrow from "../../assets/images/arrow.png";
import ExpertImg from "../../assets/images/Ellipse 39.png";
import Language from "../../common/Language";

const HowitWork = () => {
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 426px)");
  const [reviews, setReviews] = useState([]);
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const fetchReviewsData = () => {
    request(Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.Home,
    "get", "", "", (error, result) => {
      if (error) {
      } else {
        if (result.status == 200) {
          setReviews(result.data.data.reviews);
        } else if (result.status == 401) {
        } else {
        }
      }
    });
  };

  useEffect(() => {
    fetchReviewsData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: false,
    className: "reviews-carousel",
  };

  return (
    <div className="home-wrapper">
      <div className="w-full custom-border pb-8 md:pb-12">
        <div className="custom-container md:mt-8">
          <Grid container className="mobile-direction">
            <Grid xs={12} sm={7} className="md:pr-4">
            <div className="flex flex-col mt-4 md:mt-2">
              <p className="section-detail">
                <Language>how_it_work_finding_para</Language>
              </p>
              <p className="section-detail mt-6">
              <Language>how_it_work_stop_worrying_para</Language>
              </p>
              </div>
            </Grid>
            <Grid xs={12} sm={5}>
              <div className="img-parent">
                <img
                  src={TalkToProfessional}
                  alt="TalkToProfessional"
                  className="plan-img"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="w-full custom-border pb-8 md:pb-12">
        <div className="custom-container mt-7 md:mt-12">
          <h2 className="section-title text-center">
            <Language>you're_on_your_way_to_amazing_results_and_healthier_lifestyle</Language>
          </h2>
          <Grid className="mt-8 md:mt-20 section-step" container>
            <Grid xs={12} sm={1}></Grid>
            <Grid xs={12} sm={2}>
              <Grid container className="flex items-center mb-7 sm:mb-0">
                <Grid xs={3} sm={12}>
                  <div className="flex justify-center flex-col items-center">
                    <div className="circle">1</div>
                  </div>
                </Grid>
                <Grid xs={8} sm={12}>
                  <p className="circle-point mt-7"><Language>Choose_your_goal</Language></p>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={2} className="hide-errow">
              <Grid container>
                <Grid xs={12}>
                  <div className="flex justify-center self-center">
                    <EastIcon style={{ fontSize: "60px", color: "#1B4633" }} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={3}>
              <Grid container className="flex items-center mb-7 sm:mb-0">
                <Grid xs={3} sm={12}>
                  <div className="flex justify-center flex-col items-center">
                    <div className="circle">2</div>
                  </div>
                </Grid>
                <Grid xs={8} sm={12}>
                  <p className="circle-point mt-7">
                    <Language>Sign_up_to_a_plan</Language>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={2} className="hide-errow">
              <Grid container>
                <Grid xs={12}>
                  <div className="flex justify-center self-center">
                    <EastIcon style={{ fontSize: "60px", color: "#1B4633" }} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sm={2}>
              <Grid container className="flex items-center sm:mb-0">
                <Grid xs={3} sm={12}>
                  <div className="flex justify-center flex-columns flex-col items-center">
                    <div className="circle">3</div>
                  </div>
                </Grid>
                <Grid xs={8} sm={12}>
                  <p className="circle-point mt-7"><Language>review_and_start_your_plan</Language></p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="w-full custom-border pb-8 md:pb-12">
        <div className="custom-container mt-7 md:mt-12">
          <h2 className="section-title text-center">
            <Language>meet_our_expert_nutritionists</Language>
          </h2>
          <div className="flex flex-row flex-wrap justify-center mt-8">
              <div className="flex flex-col justify-center items-center expert-box p-2 my-2 md:m-6">
                    <div className="expert-img">
                        <img src={Constant.URL.ImageUrl + '/images/Dalia.png'} className="w-full h-auto object-cover" alt="Nutritionists"/>
                    </div>
                    <p className="expert-name mt-4">
                  <Language>Dalia</Language> 
                    </p>
              </div>
              <div className="flex flex-col justify-center items-center expert-box p-2 my-2 md:m-6">
                    <div className="expert-img bg-white">
                        <img src={Constant.URL.ImageUrl + '/images/Serena.png'}  className="w-full h-auto object-coverl" alt="Nutritionists"/>
                    </div>
                    <p className="expert-name mt-4">
                    <Language>Serena</Language>
                    </p>
              </div>
              <div className="flex flex-col justify-center items-center expert-box p-2 my-2 md:m-6">
                    <div className="expert-img bg-white">
                        <img src={Constant.URL.ImageUrl + '/images/Nermin.png'}  className="w-full h-auto object-cover" alt="Nutritionists"/>
                    </div>
                    <p className="expert-name mt-4">
                    <Language>Nermin</Language>
                    </p>
              </div>
          </div>
        </div>
      </div>
      <div className="mt-16 reviews">
        <div className="text-center mb-8">
          <h3 className="section-title"><Language>customer_reviews</Language></h3>
        </div>
        <div className="custom-container">
          <Slider {...settings}>
            {reviews.map((plan, index) => (
              <Review key={index} plan={plan} />
            ))}
          </Slider>
        </div>
      </div>
      <div className="w-full section-faq">
        <FAQs />
      </div>
    </div>
  );
};

export default HowitWork;
