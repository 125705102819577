import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./scss/index.scss";
import "./App.css";
import RouteProvider from "./routes";

function App() {

  return (
    <div className="App">
      <RouteProvider />
    </div>
  );
}

export default App;
