import React, { useState, useEffect } from "react";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";

const TermsAndConditions = () => {
  const [termsAndConditions, setTermsAndConditions] = useState();
  const [loader, setLoader] = useState(false);
  const [apiError, setApiError] = useState('');
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const fetchTermsAndConditionsData = () =>
  {
    setLoader(true);
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.TermsAndConditions,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
          setApiError(error.response.data.message);
        } else {
          if (result.status == 200) {
          setTermsAndConditions(result.data.data);
          } else {
            setApiError(result.response.data.message);
          }
        }
      }
    );
  }

  useEffect(() => {
    fetchTermsAndConditionsData();
  }, []);

  return (
    <>
      <Loader isLoading={loader}/>
      {termsAndConditions ?
      <div className="main-wrapper w-full">
        <div className="custom-container">
          <h1 className="section-title">{termsAndConditions.spotlight_title}</h1>
          <ul className="policy-content">
            {apiError ? apiError : <div dangerouslySetInnerHTML={{ __html: termsAndConditions.termsandcondition.desc }} />}
          </ul>
        </div>
      </div>
    : apiError}
    </>
  );
};

export default TermsAndConditions;
