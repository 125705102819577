import React from "react";
import { useNavigate } from "react-router-dom";
import Language from "../../common/Language";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer-wrapper">
      <div className="mx-auto footer">
        <div className="flex flex-wrap justify-center items-center footer-top">
          <div className="flex flex-col md:flex-row items-center">
            <div className="mx-4 my-3 md:my-0 footer-menu" onClick={() => navigate("/how-it-works")}><Language>how_it_works</Language></div>
            <div className="mx-4 my-3 md:my-0 footer-menu" onClick={() => navigate("/contact-us")}><Language>contact_us</Language></div>
            <div className="mx-4 my-3 md:my-0 footer-menu" onClick={() => navigate("/privacy-policy")}><Language>privacy_policy</Language></div>
            <div className="mx-4 my-3 md:my-0 footer-menu" onClick={() => navigate("/terms-conditions")}><Language>terms_and_conditions</Language></div>
          </div>
    
        </div>
      </div>
    </div>
  );
};

export default Footer;
