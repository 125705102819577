import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Constant from "../../components/Constant";

function Return(props) {
  // Initial State
  const navigate = useNavigate();
  // Local State
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    async function fetchClientSecret(publicKey) {
      try {
        const response = await fetch(
          Constant.URL.baseUrl + Constant.URL.stripeStatus,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ session_id: sessionId }),
          }
        );
        const data = await response.json();
        setStatus(data.status);
      } catch (error) {
        console.error("Error fetching client secret:", error);
        throw error;
      }
    }
    fetchClientSecret();
  }, []);

  if (status === "open") {
    navigate("/checkout");
  }

  if (status === "complete") {
    navigate("/success-page");
  }

  return null
}

export default Return;
