import { Route, Routes } from "react-router-dom";
import { Checkout, Home, PageNotFound, PersonalizeMealPlan } from "../pages";
import RequireAuth from "./RequireAuth";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPasssword";
import ResetPassword from "../pages/ResetPassword";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../components/Terms_and_condition/termsandcondition";
import HowitWork from "../pages/howitwork";
import Profile from "../components/profile/Profile";
import ContactUs from "../pages/ContactUs";
import Register from "../pages/Register";
// import BlogPage from '../pages/BlogPage';
import Blog from "../components/Blog/Blog";
import BlogDetails from "../components/BlogDetails/BlogDetails";
import BlogDetails2 from "../components/BlogDetails/BlogDetails2";
import Blog2 from "../components/Blog2/Blog2";
import SuccessPage from "../pages/statusPages/SuccessPage";
import NotFoundPage from "../pages/statusPages/NotFoundPage";
import CheckoutForm from "../pages/StripePayment/CheckoutForm";
import Return from "../pages/StripePayment/Return";

const RouteProvider = () => {
  const authRoutes = [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "personalize-meal-plan",
      component: <PersonalizeMealPlan />,
    },
    {
      path: "checkout",
      component: <Checkout />,
    },
    {
      path: "/login",
      component: <Login />,
    },
    {
      path: "/forgot-password",
      component: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      component: <ResetPassword />,
    },
    {
      path: "/privacy-policy",
      component:<PrivacyPolicy />
    },
    {
      path: "/terms-conditions",
      component:<TermsAndConditions />
    },
    {
      path: "/how-it-works",
      component:<HowitWork />
    },
    {
      path: "/profile",
      component:<Profile />
    },
    {
      path: "/register",
      component: <Register />,
    },
    {
      path: "/contact-us",
      component:<ContactUs />
    },
    {
      path: "/blog",
      component:<Blog />
    },
    {
      path: "/blog/:id",
      component:<Blog />
    },
    {
      path: "/blog-detail",
      component:<BlogDetails />
    },
    {
      path: "/blog-detail2/:tags/:blogId",
      component:<BlogDetails2 />
    },
    {
      path: "/blog2",
      component:<Blog2 />
    },
    {
      path: "/blog2/:id",
      component:<Blog2 />
    },
    {
      path: "/success-page",
      component:<SuccessPage />
    },
    {
      path: "/notfound-page",
      component:<NotFoundPage />
    },
    {
      path: "/stripe-checkout",
      component:<CheckoutForm />
    },
    {
      path: "/stripe-return",
      component:<Return />
    },
  ];

  return (
    <Routes>
      {authRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          exact={true}
          element={<RequireAuth>{route.component}</RequireAuth>}
        />
      ))}

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default RouteProvider;
