import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, Polygon } from "react-leaflet";
import L from "leaflet";
import locationMarker from "../../assets/images/location-picker.png";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import "leaflet/dist/leaflet.css";

const Map = ({ defaultLocation, triangleCoordinates, zoomValue }) => {
  const initialClickedLocation = JSON.parse(localStorage.getItem("latLng")) || {
    lat: 24.349166, // default latitude
    lng: 54.486391, // default longitude
  };
  const [clickedLocation, setClickedLocation] = useState(
    initialClickedLocation
  );
  const [userLocation, setUserLocation] = useState(null);
  const [zoom] = useState(zoomValue ? zoomValue : 2);

  const defaultLatLong = {
    lat: 24.349166, // default latitude
    lng: 54.486391, // default longitude
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newUserLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          localStorage.setItem("latLng", JSON.stringify(newUserLocation));
          if (defaultLocation) {
            setUserLocation(newUserLocation);
          }
          setClickedLocation(newUserLocation); // Set clickedLocation on initial load
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser.");
    }
  }, []);

  const handleMapClick = (event) => {
    setClickedLocation({
      lat: event.latlng.lat,
      lng: event.latlng.lng,
    });
    localStorage.setItem(
      "latLng",
      JSON.stringify({
        lat: event.latlng.lat,
        lng: event.latlng.lng,
      })
    );
  };

  const handleMarkerDrag = (event) => {
    if (event.target && event.target.getLatLng) {
      const newLocation = event.target.getLatLng();
      setClickedLocation({
        lat: newLocation.lat,
        lng: newLocation.lng,
      });
      localStorage.setItem(
        "latLng",
        JSON.stringify({
          lat: newLocation.lat,
          lng: newLocation.lng,
        })
      );
    }
  };

  const customIcon = new L.Icon({
    iconUrl: locationMarker,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
  return (
    <div style={{ position: "sticky" }}>
      <MapContainer
        center={userLocation || defaultLatLong}
        zoom={userLocation ? 10 : zoom}
        style={{ height: "70vh", width: "100%" }}
        onClick={handleMapClick}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution=""
        />
        {triangleCoordinates && (
          <Polygon
            positions={triangleCoordinates}
            pathOptions={{
              color: "#FF6347",
              fillColor: "#FFB6C1",
              fillOpacity: 0.3,
              dashArray: "5,5",
            }}
          />
        )}
        <ReactLeafletGoogleLayer
          apiKey="AIzaSyAoo5OCbf7xDE0cxAjNvC5M5ANu3dKrBiU"
          type={"hybrid"}
        />
        {clickedLocation && (
          <Marker
            position={clickedLocation}
            icon={customIcon}
            draggable={true}
            eventHandlers={{ dragend: handleMarkerDrag }}
          >
            {/* <Popup>
              A marker at {clickedLocation.lat}, {clickedLocation.lng}.
            </Popup> */}
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default Map;
