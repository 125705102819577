import React, { useState, useEffect } from "react";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";

async function fetchPublicKey() {
  try {
    const response = await fetch(
      Constant.URL.baseUrl + Constant.URL.getStriprKey
    );
    const data = await response.text();
    return data;
  } catch (error) {
    console.error("Error fetching public key:", error);
    throw error;
  }
}

async function fetchClientSecret(publicKey) {
  try {
    const response = await fetch(
      Constant.URL.baseUrl + Constant.URL.stripeCheckout,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: localStorage.getItem("payment-data"),
      }
    );
    const data = await response.json();
    return data.clientSecret;
  } catch (error) {
    console.error("Error fetching client secret:", error);
    throw error;
  }
}

function CheckoutForm(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [publicKey, setPublicKey] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedPublicKey = await fetchPublicKey();
        setPublicKey(fetchedPublicKey);
        const fetchedClientSecret = await fetchClientSecret(fetchedPublicKey);
        setClientSecret(fetchedClientSecret);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div id="checkout" className="sm:py-[2rem] md:pt-[4rem] py-10 screen-h relative">
      <Loader isLoading={clientSecret ? false : true} />
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={loadStripe(publicKey)}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
}

export default CheckoutForm;
