import React from "react";

var base_url = `${window.location.origin.toString()}/admin/api/`

var URL = {
    baseUrl: base_url,
    ImageUrl:window.location.origin.toString(),
    Home: 'home',
    Login: base_url + 'login',  
    registrations: base_url + 'register-customer',
    ForgotPassword: base_url + 'forgot_password',
    newPassword: base_url + 'new_password',
    ResetPassword: base_url + 'change_password',
    TermsAndConditions: 'static/terms',
    PrivacyPolicy: 'static/privacy',
    Faq: 'faq',
    MealPlan: 'meal-plan',
    RegisterPageData: 'registration-datas',
    ContactUs: base_url + 'contact-us',
    Logout: base_url + 'logout',
    MealPlanDetails: 'meal-plan',
    UpdateProfilePicture: base_url + 'customer_profile',
    PriceCalculator: 'price_calculator',
    Checkout: base_url + 'checkout',
    BlogByTag: 'blog',
    BlogDetail: 'blog_details',
    Emirates:'get-locations',
    SavePipeDrive: 'savepipedrive',
    UserData: base_url +'customer',
    EditProfile: base_url +'edit-profile',
    getStriprKey: 'get-stripe-key',
    stripeCheckout: 'stripe_checkout',
    stripeStatus: "stripeStatus"
}

export default {URL};