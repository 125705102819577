const validatecustomerLeads = (customerLeads, prevCustomerLeads) => {
  if (customerLeads.name.length > 0 && customerLeads.name.length >= 3) {
    if (customerLeads.email.length > 0) {
      const emailRegex = /^[a-zA-Z0-9]+@.+$/;
      const isValidEmail = emailRegex.test(customerLeads.email);
      if (isValidEmail) {
        if (customerLeads.phone.length > 0) {
          const numberRegex = /^\d{8,11}$/;
          const isValidNumber = numberRegex.test(customerLeads.phone);
          if (isValidNumber) {
            const valuesChanged =
              customerLeads.name !== prevCustomerLeads.name ||
              customerLeads.email !== prevCustomerLeads.email ||
              customerLeads.phone !== prevCustomerLeads.phone;
            if (valuesChanged) {
              return true;
            }
          }
        }
      }
    }
  }
};

export default validatecustomerLeads;
