import React, { useState, useEffect } from "react";
import { Button, Grid , Modal, Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import TextInputField from "../../common/inputFields/TextInputField";
import { styled } from "@mui/material/styles";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { values } from "lodash";
import Loader from "../../components/Loader/Loader";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { ClearIcon } from "@mui/x-date-pickers";

const Profile = () => {
  const [apiError, setApiError] = useState("");
  const [loader, setLoader] = useState(false);
  const token = localStorage.getItem("token");
  const [message, setMessage] = useState('');
  const [userDetails, setUserDetails] = useState();
  const [updateImage, setUpdateImage] = useState(() => {
    const storedImage = localStorage.getItem("profileImage");
    return storedImage ? JSON.parse(storedImage) : [];
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    localStorage.setItem("profileImage", JSON.stringify(updateImage));
  }, [updateImage]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = (values) => {
    const params = {
      token: token,
      password: values.password,
      confirm_password: values.confirmPassword,
    };
    setLoader(true);
    request(Constant.URL.ResetPassword, "post", params, "", (error, result) => {
      setLoader(false);
      if (error) {
        setApiError(error.response.data.message);
      } else {
        if (result.status == 200) {
          setMessage(result.data.message);
          setTimeout(() =>
          {
             window.location.reload();
          }, 500);
        } else if (result.status == 401) {
          setApiError(result.data.message);
        } else {
          setApiError(result.response.data.message);
        }
      }
    });
  };

  const handleFileChange = (event) => {      
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('profile_pic', file)
    axios.post(Constant.URL.UpdateProfilePicture, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+localStorage.getItem('token'),
        },
    }).then(x => setUpdateImage(x.data.data))
};

  const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.password === value;
      })
  });
  const phoneValidationSchema = Yup.object({
      phone: Yup.string()
      .required("Phone number is required")
      .min(7, "Phone number must be at least 7 digits")
      .max(12, "Phone number must be at most 12 digits"),
  });

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const userData = () => {
    {
      const token = localStorage.getItem("token"); 
      if(token){
        request(Constant.URL.UserData, "get", "" ,token, (error, result) => {
          if (error) {
          } else {
            if (result.status == 200) {
              setUserDetails(result.data.data);
            } else if (result.status == 401) {
            } else {
            }
          }
        });
      }
    }
  };
  
  useEffect(() => {
    userData();
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div className="profile-wrapper">
      <Loader isLoading={loader}/>
      <div className="custom-container">
        <h2 className="profile-title">Edit your profile</h2>
           {/* Modal */}
      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',  bgcolor: 'background.paper', boxShadow: 24, p: 4 ,bgcolor: "background.paper", boxShadow: 24,}}>
          <div className="entire-modal">
              <div className="modal-heading" style={{ display: 'flex',flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography id="modal-title" variant="h6" component="h2" style={{ flex: 1, textAlign: 'center',order:isMobile ? '2' : '1' }}>
                  Edit Personal Info
                </Typography>
                <ClearIcon
                        onClick={() => closeModal()}
                        style={{
                          color: "#1B4633",
                          fontSize: "40px",
                          textAlign: "right",
                          float: "right",
                          cursor: "pointer",
                          order:isMobile ? '1' : '2'
                        }}
                      />
              </div>
              <Grid container className="px-4">
                <div className="modal-content" style={{display:'flex', flexDirection:isMobile ? 'column' : 'row', marginTop:'2rem'}}>
                <Grid md={5} className="mt-5 md:mt-0">
                    <div className="modal-img-div" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className="profile-img mb-4">
                          <img src={userDetails?.profile_pic} className="w-full h-full" style={{width:'120px',height:'120px',borderRadius:'50%',objectFit:'cover'}} />
                        </div>
                        <Button
                          component="label"
                          variant="contained"
                          className="profile-btn edit-profile"
                          style={{
                            border: '1px solid #32B032',
                            borderRadius: '6px',
                            color: '#32B032',
                            textTransform: 'uppercase',
                            fontFamily: 'Urbanist',
                            fontWeight: 500,
                            fontSize: '15px',
                            lineHeight: '18px',
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                          }}
                        >
                        Update photo
                          <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                        </Button>
                    </div>
                  </Grid>
                  <Grid md={7} className="lg:pl-10" style={{marginTop: isMobile ? '1rem' : '',}}>
                    <div className="modal-form-div">
                    <Formik
                        initialValues={{
                          name: userDetails?.name || '',
                          email: userDetails?.email || '',
                          phone: userDetails?.phone || '',
                        }}
                        validationSchema={phoneValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          // Handle form submission
                          const params = {
                            phone: values.phone, // Include the phone number in the request
                          };
                      
                          setLoader(true);
                      
                          request(Constant.URL.EditProfile, "post", params, token, (error, result) => {
                            setLoader(false);
                            if (error) {
                              setApiError(error.response.data.message);
                            } else {
                              if (result.status === 200) {
                                setMessage(result.data.message);
                                setTimeout(() => {
                                  window.location.reload();
                                }, 500);
                              } else if (result.status === 401) {
                                setApiError(result.data.message);
                              } else {
                                setApiError(result.response.data.message);
                              }
                            }
                            setSubmitting(false); // Reset the submitting state
                          });
                        }}
                      >
                        
      {({ values, handleChange, errors, handleSubmit }) => (
                          <Form>
                            <TextInputField
                              margin="dense"
                              name="name"
                              label="Name"
                              value={values.name}
                              InputProps={{
                                readOnly: true,
                              }}
                              disabled
                            />
                            <TextInputField
                              margin="dense"
                              name="email"
                              label="Email"
                              InputProps={{
                                readOnly: true,
                              }}
                              value={values.email}
                              disabled
                            />
                            <TextInputField
                              margin="dense"
                              name="phone"
                              label="Phone"
                              value={values.phone}
                              onChange={handleChange("phone")}
                              error={errors.phone ? true : false}
                              helperText={errors.phone}
                            />
                          <div className="submit-btns" style={{display:'flex',justifyContent:'center'}}>
                            <Button
                            className="mr-4"
                              variant="contained"
                              type="submit"
                              style={{
                                border: '1px solid #FF2E00',
                                borderRadius: '6px',
                                color: '#FF2E00',
                                textTransform: 'uppercase',
                                fontFamily: 'Urbanist',
                                fontWeight: 500,
                                fontSize: '15px',
                                lineHeight: '18px',
                                textAlign: 'center',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                marginTop: '20px',
                                marginRight:'10px'
                              }}
                            >
                              cancel
                            </Button>
                            <Button
                              variant="contained"
                              type="submit"
                              style={{
                                border: '1px solid #32B032',
                                borderRadius: '6px',
                                color: '#32B032',
                                textTransform: 'uppercase',
                                fontFamily: 'Urbanist',
                                fontWeight: 500,
                                fontSize: '15px',
                                lineHeight: '18px',
                                textAlign: 'center',
                                backgroundColor: 'transparent',
                                boxShadow: 'none',
                                marginTop: '20px',
                              }}
                            >
                              confirm
                            </Button>
                          </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </Grid>
                </div>
              </Grid>
          </div>
        </Box>
      </Modal>
        <Grid container className="profile-box mt-7 mb-10">
          <Grid xs={12} sm={6} className="flex justify-center py-10 md:py-20 items-center custom-right-border">
            <div className="flex flex-col justify-center items-center">
              <div className="profile-img mb-4">
                <img src={userDetails?.profile_pic} className="w-full h-full" />
                {/* <img src={profilePic} className="w-full h-full" /> */}
              </div>
              <div className="flex flex-col items-center mt-2">
                <p className="profile-user-detail">{userDetails?.name}</p>
                <p className="profile-user-detail"> {userDetails?.phone}</p>
              </div>
              <Button
                component="label"
                variant="contained"
                className="profile-btn edit-profile"
                onClick={openModal}
              >
               Edit Profile
                {/* <VisuallyHiddenInput type="file" onChange={handleFileChange} /> */}
              </Button>
            </div>
          </Grid>
          <Grid xs={12} sm={6} className="flex justify-center items-center pt-8 md:pt-0">
            <div className="flex flex-col justify-center items-center">
              <h4 className="profile-user-detail mb-4">Change password</h4>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleChange, errors, handleSubmit, resetForm }) => (
                  <Form>
                    <TextInputField
                      name="password"
                      type="password"
                      wrapperClassName="w-full mb-0"
                      inputClassName="form-control"
                      placeholder="New Password"
                      value={values.password}
                      onChange={handleChange("password")}
                    />
                    <div style={{ color: "red", fontSize: 12 }}>
                      {errors.password}
                    </div>
                    <TextInputField
                      name="confirmPassword"
                      type="password"
                      wrapperClassName="w-full mb-0"
                      inputClassName="form-control"
                      placeholder="Repeat Password"
                      value={values.confirmPassword}
                      onChange={handleChange("confirmPassword")}
                    />
                    <div style={{ color: "red", fontSize: 12 }}>
                      {errors.confirmPassword}
                    </div>
                    <Button
                      variant="contained"
                      type="button"
                      className="profile-btn mt-0 w-full"
                      onClick={handleSubmit}
                    >
                      Change password
                    </Button>
                  </Form>
                )}
              </Formik>
              <div style={{backgroundColor: 'transparent', color: '#32B032'}}>{message ? message+'!' : null}</div>
              <div
                style={{
                  color: "red",
                  fontSize: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {apiError ? apiError : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Profile;
