import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { OutlinedTextFieldProps, TextField } from "@mui/material";
import Language from "../../../common/Language";
import { useTranslation } from 'react-i18next';
interface TextInputProps extends OutlinedTextFieldProps {
  label: string;
  name: string;
}

const InputField = ({ name, label, ...props }) => {
  const [field, meta] = useField(name);
  const { t, i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        return localStorage.getItem('selectedLanguage') || 'en';
      });
    
    useEffect(() => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
        i18n.changeLanguage(selectedLanguage);
      }, [selectedLanguage, i18n]);
  return (
    <TextField
      fullWidth
      placeholder={t(label)}
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      onBlur={(e) => e.preventDefault()}
    />
  );
};

export default InputField;
