import { useState, useEffect } from "react";
import {
  Box,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../Loader/Loader";
import Language from "../../common/Language";

const FAQs = () => {
  const [open, setOpen] = useState();
  const [faqTabs, setFaqTabs] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [selectedTab, setSelectedTab] = useState('');
  const [seletedFaq, setSelectedFaq] = useState();
  const [loader,setLoader] = useState(true);
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const fetchFaqData = () => {
    setLoader(true);
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.Faq,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
        } else {
          if (result.status == 200) {
            setFaqList(result.data.data);
            setFaqTabs(Object.keys(result.data.data));
            setSelectedFaq(result.data.data[Object.keys(result.data.data)[0]]);
          } else {
          }
        }
      }
    );
  };

  useEffect(() => {
    fetchFaqData();
  }, []);

  return (
    <div className="faq">
      <div className="faq-heading"><Language>frequently_asked_questions</Language></div>
      <Loader isLoading={loader}/> 
      <div className="flex justify-center faq-tabs">
        <div className="flex flex-nowrap md:flex-wrap flex-row custom-container justify-start items-center overflow-tab">
          {faqTabs?.map((tab, index) => (
            <div key={index} className={`faq-tab ${((selectedTab || faqTabs[0]) === tab) ? 'active' : ''}`} onClick={() => (setSelectedTab(tab), setSelectedFaq(faqList[tab]))}>
              {tab}
            </div>
          ))}
        </div>
      </div>
      <div className="faq-list custom-container mt-9">
        {seletedFaq?.map((faq, index) => (
          <Box
            key={index}
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              color: "#1B4633",
              backgroundColor: "white",
              borderRadius: "6px",
              cursor: "pointer",
              fontFamily:'Urbanist',
              border:'0.5px solid #1B4633'
            }}
            onClick={() => {
              setOpen(open === faq.id ? "" : faq.id);
            }}
          >
            <CardHeader
              className="text"
              title={faq.title}
              action={
                <IconButton aria-label="expand" size="small">
                  {open === faq.id ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              }
            ></CardHeader>
            <Collapse in={open === faq.id} timeout="auto" unmountOnExit>
              <CardContent className="answer"><div dangerouslySetInnerHTML={{ __html: faq.description }} /></CardContent>
            </Collapse>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
