import React, { FC, useState } from "react";
import { Formik, FormikConfig, useFormikContext, FormikValues } from "formik";
import FormNavigation from "./FormNavigation";
import { Grid, Step, StepLabel, Stepper } from "@mui/material";

interface Props extends FormikConfig<FormikValues> {
  children: React.ReactNode;
}

const MultiStepForm = ({ children, initialValues, onSubmit, setStepStats, daysError }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);

  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;

  const isLastStep = stepNumber === totalSteps - 1;
  const [latLng, setLatLng] = useState();

  const nextStep = (values: FormikValues) => {
    if (
      localStorage.getItem("deliveryPlace") == "" ||
      localStorage.getItem("deliveryPlace") == null
    ) {
      localStorage.setItem("next", false);
      localStorage.setItem("checkDeliveryDaysError", true);
      return;
    }
    if (
      localStorage.getItem("deliveryDays") == "" ||
      localStorage.getItem("deliveryDays") == null
    ) {
      localStorage.setItem("next", false);
      localStorage.setItem("checkDeliveryDaysError", true);
      return;
    }

    localStorage.setItem("next", true);
    setSnapshot(values);
    setStepNumber(stepNumber + 1);
  };

  const previousStep = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(stepNumber - 1);
  };

  const handleSubmit = async (values: FormikValues, actions: FormikValues) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit;
    }
    if (isLastStep) {
      values.city_id = localStorage.getItem("city_id");
      values.masterLocation = localStorage.getItem("masterLocation");
      values.PreferredDeliveryTime = localStorage.getItem(
        "PreferredDeliveryTime"
      );
      values.areaTime = localStorage.getItem("areaTime");
      values.experienceLevel = localStorage.getItem("experienceLevel");
      values.acceptTearmAndCondition = localStorage.getItem(
        "acceptTearmAndCondition"
      );
      values.deliveryDays = localStorage.getItem("deliveryDays");
      values.deliveryPlace = localStorage.getItem("deliveryPlace");
      localStorage.clear();
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      nextStep(values);
    }
  };

  const handlesendStepData = (data) => {
    setStepStats(data);
  }

  return (
    <div>
      <Formik
        initialValues={snapshot}
        onSubmit={handleSubmit}
        validationSchema={step.props.validationSchema}
      >
        {(formik) => (
          <div className="registration-wapper">
            <div className="stepper-container custom-container mb-10 md:mb-20">
              <form onSubmit={formik.handleSubmit}>
                <div className="step-box">
                  <Stepper activeStep={stepNumber} className="step-data">
                    {steps.map((currentStep) => {
                      const label = currentStep.props.stepName;
                      return (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>

                <Grid container className="mt-10">
                  <Grid xs={12}>{step}</Grid>
                  <Grid xs={12}>
                    <FormNavigation
                      isLastStep={isLastStep}
                      hasPrevious={stepNumber > 0}
                      onBackClick={() => previousStep(formik.values)}
                      sendStepData={handlesendStepData}
                      daysError={daysError}
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepForm;
export const FormStep = ({ stepName = "", children }) => children;
