import React, { FC } from "react";
import { Button } from "@mui/material";
import { FormikValues } from "formik";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Language from "../../../common/Language";

interface Props1 {
  hasPrevious?: boolean;
  onBackClick: (values: FormikValues) => void;
  isLastStep: boolean;
}

const FormNavigation = (props) => {
  const handelChange = () => {
    if (!props.daysError) {
      if (
        (localStorage.getItem("deliveryPlace") == "" ||
          localStorage.getItem("deliveryPlace") == null) &&
        (localStorage.getItem("deliveryPlace") == "" ||
          localStorage.getItem("deliveryPlace") == null)
      ) {
        props.sendStepData({ is_set_delivery_place_value: true });
        props.sendStepData({ is_set_delivery_days_value: true });
        return;
      }

      if (
        localStorage.getItem("deliveryPlace") == "" ||
        localStorage.getItem("deliveryPlace") == null
      ) {
        localStorage.setItem("next", false);
        localStorage.setItem("checkDeliveryDaysError", true);
        props.sendStepData({ is_set_delivery_place_value: true });
        return;
      }
      if (
        localStorage.getItem("deliveryDays") == "" ||
        localStorage.getItem("deliveryDays") == null
      ) {
        localStorage.setItem("next", false);
        localStorage.setItem("checkDeliveryDaysError", true);
        props.sendStepData({ is_set_delivery_days_value: true });
        return;
      }
      if (
        props.isLastStep &&
        (localStorage.getItem("acceptTearmAndCondition") == "" ||
          localStorage.getItem("acceptTearmAndCondition") == null ||
          localStorage.getItem("acceptTearmAndCondition") == 0)
      ) {
        localStorage.setItem("next", false);
        props.sendStepData({ is_accept_tearm_and_condition: false });
        return;
      } else {
        props.sendStepData({ is_accept_tearm_and_condition: true });
      }
    }
  };

  return props.hasPrevious ? (
    <div className="step-two-btn">
      {props.hasPrevious && (
        <Button
          variant="contained"
          className="!m-2 btn-previous"
          type="button"
          onClick={props.onBackClick}
        >
          <ChevronLeftIcon className="mr-3" /> <Language>previous</Language>
        </Button>
      )}
      <Button
        type="submit"
        className="!m-2 next-btn"
        variant="contained"
        onClick={handelChange}
        disabled={props.daysError}
      >
        {props.isLastStep ? (
          <Language>submit</Language>
        ) : (
          <Language>next_step</Language>
        )}{" "}
        <ChevronRightIcon className="ml-3" />
      </Button>
    </div>
  ) : (
    <div className="register-next-btn">
      {props.hasPrevious && (
        <Button
          variant="contained"
          className="!m-2 btn-previous"
          type="button"
          onClick={props.onBackClick}
        >
          <ChevronLeftIcon className="mr-3" /> <Language>previous</Language>
        </Button>
      )}
      <Button
        type="submit"
        className="!m-2 next-btn"
        variant="contained"
        onClick={handelChange}
        disabled={props.daysError}
      >
        <span>
          {props.isLastStep ? (
            <Language>submit</Language>
          ) : (
            <Language>next_step</Language>
          )}
        </span>{" "}
        <ChevronRightIcon className="right-arrow-step-one md:ml-3" />
      </Button>
    </div>
  );
};

export default FormNavigation;
