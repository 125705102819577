import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Modal,
  Fade,
  Typography,
  Backdrop,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FAQs from "../../components/faqs/FAQs";
import ChefCookedFood from "../../assets/images/achieve your goals/icon-menu-custom.svg.png";
import CustomizableMealsPlans from "../../assets/images/achieve your goals/icon-menu-stack.svg.png";
import FreeDailyDelivery from "../../assets/images/achieve your goals/icon-menu-changes.svg.png";
import ClearIcon from "@mui/icons-material/Clear";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";
import Language from "../../common/Language";


const ChooseYourPreferences = ({ setMealId, plans }) => {

  const handleChangeMealId = (mealId) =>
  {
    setMealId(mealId);
  }

  return (
   <div>

        <div >
          <div className="home-plans">
          <Grid container>
          {plans.map((meal, index) => (
            <Grid xs={12} sm={6} md={6} className="plan-parnet-box mt-5">
              <div className="flex home-plan-top-container" onClick={() => handleChangeMealId(meal.id)}>
                <img src={meal.thumbnail} className="w-full h-full" />
                <div className="flex flex-col items-start justify-between home-plan-top-content">
                  <span
                    className="home-plan-title"
                  >
                    {meal.meal_plan_name}
                  </span>
                </div>
              </div>
              <div>{meal.summary}</div>
            </Grid>
          ))}
        </Grid>
          </div>
        </div>
   </div>
  );
};

const CustomizeYourPlan = (props) => {
  const navigate = useNavigate();
  const { mealId, plans, mealPlanName } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [customizePlan, setCustomizePlan] = useState(plans);
  const [choosePlan, setChoosePlan] = useState(true);

  useEffect(() =>
  {
    if(plans != undefined && choosePlan){
      setCustomizePlan(plans[0]);
    }
  }, [plans]);
  
  const handleTabClick = (plan, index) => {
    setChoosePlan(false);
    setActiveTab(index);
    setCustomizePlan(plan);
  };

  const navigateToCheckout = () => {
    localStorage.setItem('plan', JSON.stringify(customizePlan))
    localStorage.setItem('mealId', mealId)
    localStorage.setItem('mealPlanName', mealPlanName)
    navigate('/checkout', { state: { plan: customizePlan, mealId: mealId, mealPlanName: mealPlanName }})
  }

  return (
    <div>
      <div className="how-many-calories mt-4">
        <div className="tabs">
          {plans != undefined && plans?.map((x, index) => (
            <div
              className={`tab ${activeTab === index ? "active" : ""}`}
              key={index}
              onClick={() => (handleTabClick(x, index))}
            >
              <div className="days">{x.plan_name}</div>
            </div>
          ))}
        </div>
      </div>

      <button className="customised-plan-btn" onClick={() => navigateToCheckout()}><Language>proceed_to_checkout</Language></button>
    </div>
  );
};

const PersonalizeMealPlan = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(2);
  const [loader, setLoader] = useState(false);
  const [mealPlanDetails, setMealPlanDetails] = useState([]);
  const location = useLocation();
  const selectedLanguage = localStorage.getItem('selectedLanguage');

  const [mealId, setMealId] = useState("");
  const handleDataFromChild = (mealId) =>
  {
    setMealId(mealId);
  }

  const fetchPersonalizeMealPlanData = (id) => {
    setLoader(true);
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.MealPlanDetails + '/'+id,
      "get",
      "",
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
        } else {
          if (result.status == 200) {
            setMealPlanDetails(result.data);
            localStorage.setItem('days', JSON.stringify(result.data.diet_plans[0].days))
            localStorage.setItem('week', JSON.stringify(result.data.diet_plans[0].weeks))
          } else {
          }
        }
      }
    );
  };
  const [mealPlanData, setMealPlanData] = useState([]);
  const fetchMealPlanData = () => {
    request(
      Constant.URL.baseUrl + selectedLanguage + '/'+Constant.URL.MealPlan,
      "get",
      "",
      "",
      (error, result) => {
        if (error) {
        } else {
          if (result.status == 200) {
            setMealPlanData(result.data.data);
          } else {
          }
        }
      }
    );
  }

  const handleViewMenu = (pdfUrl) => {
    window.open(pdfUrl, '_blank');
  };

  useEffect(() => {
    fetchMealPlanData()
    if (mealId)
    {
      fetchPersonalizeMealPlanData(mealId);
    }
    else{
      fetchPersonalizeMealPlanData(location.state?.planId);
    }
  }, [mealId]);

  let planArray = [];
  // if(location.state?.plan != null && location.state?.plan != undefined){
  // Object.entries(location?.state?.plan).map(([key, value]) => (
  //   Object.entries(value).map(([planKey, plan]) => (
  //     planArray.push(plan)
  //     ))
  //   ));
  // }else{
  //   navigate(`/`);
  if(mealPlanData != null && mealPlanData != undefined){
    Object.entries(mealPlanData).map(([key, value]) => (
      Object.entries(value).map(([planKey, plan]) => (
        planArray.push(plan)
        ))
      ));
    }else{
      navigate(`/`);
}
 

  const steps = [
    {
      no: 1,
      title: <Language>step_1_choose_your_preferences</Language>,
      component: <ChooseYourPreferences setMealId={handleDataFromChild} plans={planArray} />,
    },
    {
      no: 2,
      title: <Language>step_2_customize_your_plan</Language>,
      component: (<div className='md:block hidden'>
        <CustomizeYourPlan mealId={mealId ? mealId : location.state?.planId} plans={mealPlanDetails.diet_plans} mealPlanName={mealPlanDetails?.details?.name} />
        </div> ),
    },
  ];
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: 1080,
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  return (
    <div className="personalize-meal-plan">
      <Loader isLoading={loader} />
      <div className="section-1 custom-container">
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={8} className="md:pr-10">
            <div className="left">
              {steps.map((step) => (
                <Box
                  key={step.no}
                  sx={{
                    color: "#1B4633",
                    backgroundColor: "white",
                    cursor: "pointer",
                  }}
                >
                  <CardHeader
                    className="title"
                    title={step.title}
                    onClick={() => {
                      setOpen(open === step.no ? "" : step.no);
                    }}
                    
                    action={<div>
                      { step.no === 1 ?  
                      <IconButton aria-label="expand" size="small">
                         {open === step.no ? (
                          <KeyboardArrowUpIcon
                            onClick={() => {
                              setOpen(open === step.no ? "" : step.no);
                            }}
                          />
                        ) : (
                          <KeyboardArrowDownIcon
                            onClick={() => {
                              setOpen(open === step.no ? "" : step.no);
                            }}
                          />
                        )}
                      </IconButton> : '' }
                      </div>
                    }
                  ></CardHeader>

                  <Collapse in={step.no === 2 || open === step.no } timeout="auto" unmountOnExit>
                    <CardContent className="answer">
                      {step.component}
                    </CardContent>
                  </Collapse>
                </Box>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="right flex justify-center flex-col items-center">
              <div className="photo">
                <img
                  src={mealPlanDetails.details?.featured_image}
                  alt="International"
                />
              </div>

              <div className="header my-4 md:block hidden">{mealPlanDetails.details?.name}</div>
              <button className="plan-details mt-8 md:mt-0" onClick={() => handleViewMenu(mealPlanDetails?.details?.pdf)}>
                <Language>view_sample_menu</Language>
              </button>
              <div className="header mt-8 md:hidden block">{mealPlanDetails.details?.name}</div>
            </div>
          </Grid>
        </Grid>
          <div className="left md:hidden block w-full flex-col mt-5">
            <CustomizeYourPlan mealId={mealId ? mealId : location.state?.planId} plans={mealPlanDetails.diet_plans} mealPlanName={mealPlanDetails?.details?.name} />
          </div>
      </div>

      <div className="section-2 mt-10 md:mt-16 py-8 md:py-10">
        <div className="custom-container">
          <h2 className="achieve-your-goals-heading">
            <Language>achieve_your_goals_hassle_free</Language>
          </h2>
          <Grid container className="mt-8 md:mt-5">
            <Grid
              sm={4}
              className="flex justify-center items-center md:pr-8 md:py-8"
            >
              <div className="flex flex-col justify-center items-center">
                <img src={FreeDailyDelivery} alt="FreeDailyDelivery" className="goal-img" />
                <h4 className="title my-4"><Language>free_daily_delivery</Language></h4>
                <p className="desc">
                  <Language>free_daily_delivery_paragraph</Language>
                </p>
              </div>
            </Grid>
            <Grid
              sm={4}
              className="flex justify-center items-center my-8 md:my-0 md:p-8 "
            >
              <div className="flex flex-col justify-center items-center">
                <img src={ChefCookedFood} alt="FreeDailyDelivery" className="goal-img" />
                <h4 className="title my-4"><Language>chef_cooked_food</Language></h4>
                <p className="desc">
                <Language>chef_cooked_food_paragraph</Language>
                </p>
              </div>
            </Grid>
            <Grid
              sm={4}
              className="flex justify-center items-center md:pl-8 md:py-8"
            >
              <div className="flex flex-col justify-center items-center">
                <img src={CustomizableMealsPlans} alt="FreeDailyDelivery" className="goal-img" />
                <h4 className="title my-4"><Language>customizable_meal_plans</Language></h4>
                <p className="desc">
                <Language>customizable_meal_plans_paragraph</Language>
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="w-full section-faq">
        <FAQs />
      </div>
    </div>
  );
};

export default PersonalizeMealPlan;
