import React from "react";
import { useNavigate } from "react-router-dom";
import Language from "../../common/Language";

const Plan = ({ plan, plans }) => {
  const navigate = useNavigate();

  return (
    <div className="home-plan-wrapper px-2 md:px-0">
      <div className="flex home-plan-top-container" onClick={() => navigate('/personalize-meal-plan', { state: { plan: plans, planId: plan.id } } )}  style={{ cursor: 'pointer' }}>
        <img src={plan.thumbnail} alt={''} className="w-full h-full" />

        <div className="flex flex-col items-start justify-between home-plan-top-content">
         <span className="home-plan-title">{plan.meal_plan_name}</span>
          <div className="flex justify-between w-full">
            <button className="home-plan-button" onClick={() => navigate('/personalize-meal-plan', { state: { plan: plans, planId: plan.id } } )}><Language>view_plan</Language></button>
            <button className="home-plan-button"><Language>from_aed_</Language>{plan.price_per_day}<Language>_per_day</Language></button>
          </div>
        </div>
      </div>
      <div className="text-center home-plan-desc mt-2">{plan.summary}</div>
    </div>
  );
};

export default Plan;
