import { Grid, Box, Modal, Fade, Backdrop, Button } from "@mui/material";
import TextInputField from "../../common/inputFields/TextInputField";
import PostPayImg from "../../assets/images/postpay.png";
import VisaPay from "../../assets/images/visa.png";
import Mastercard from "../../assets/images/mastercard.png";
import "react-datepicker/dist/react-datepicker.css";
import { useState, useEffect, useRef } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DateField } from "@mui/x-date-pickers/DateField";
import { Formik, Form, useFormikContext, ErrorMessage } from "formik";
import * as yup from "yup";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { values } from "lodash";
import dayjs from "dayjs";
import { request } from "../../webRequester/index";
import Constant from "../../components/Constant";
import Loader from "../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Language from "../../common/Language";
import { useTranslation } from "react-i18next";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import validatecustomerLeads from "../../utils/validateCustomerLeads";

const isSunday = (day) => {
  return day === 0; // 0 corresponds to Sunday
};

const Checkout = () => {
  const navigate = useNavigate();
  const [checkoutPageData, setCheckoutPageData] = useState(() => {
    const jsonData = localStorage.getItem("checkoutPageData");
    if (jsonData) {
      const dataObject = JSON.parse(jsonData);
      return new Map(Object.entries(dataObject));
    } else {
      return new Map();
    }
  });
  const initialValues = {
    firstAndLastName:
      checkoutPageData.get("name") || localStorage.getItem("name") || "",
    email: checkoutPageData.get("email") || localStorage.getItem("email") || "",
    phoneNumber:
      checkoutPageData.get("phone") || localStorage.getItem("phone") || "",
  };
  const formikRef = useRef();
  const [openDateModal, setOpenDateModel] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [apiError, setApiError] = useState("");
  const [priceCalculatorData, setPriceCalculatorData] = useState([]);
  const [promoCode, setPromoCode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const handleOpenDateModal = () => setOpenDateModel(true);
  const handleCloseDateModal = () => setOpenDateModel(false);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const [activeTabDays, setActiveTabDays] = useState(
    checkoutPageData.get("activeTabDays")
      ? checkoutPageData.get("activeTabDays")
      : 0
  );

  const [day, setDay] = useState();
  const selectedLanguage = localStorage.getItem("selectedLanguage");
  const { t } = useTranslation();
  const handleTabDaysClick = (index, selectedDay) => {
    setActiveTabDays(index);
    priceCalculator(selectedDay);
    setCheckoutPageData((prevCheckoutPageData) => {
      const updatedCheckoutPageData = new Map(prevCheckoutPageData);
      updatedCheckoutPageData.set("activeTabDays", index);
      return updatedCheckoutPageData;
    });
  };
  // const [emirates, setEmirates] = useState([]);
  // const [selectedEmirate, setSelectedEmirate] = useState('');
  const [activeTabWeeks, setActiveTabWeeks] = useState(
    checkoutPageData.get("selectedWeek")
      ? checkoutPageData.get("selectedWeek")
      : 0
  );
  const [week, setWeek] = useState();
  const [plan, setPlan] = useState("");
  const [discount, setDiscount] = useState("");
  const [amount, setAmount] = useState("");
  const [deposit, setDeposit] = useState("");
  const [dueamount, setDueamount] = useState("");
  const [duenow, setDuenow] = useState("");
  const [customerLeads, setCustomerLeads] = useState({
    name: "",
    email: "",
    phone: "",
    from: "Checkout",
  });
  const [prevCustomerLeads, setPrevCustomerLeads] = useState({
    ...customerLeads,
  });
  const handleTabWeeksClick = (index, selectedWeek) => {
    setActiveTabWeeks(index);
    priceCalculator(selectedWeek);
    setCheckoutPageData((prevCheckoutPageData) => {
      const updatedCheckoutPageData = new Map(prevCheckoutPageData);
      updatedCheckoutPageData.set("selectedWeek", index);
      return updatedCheckoutPageData;
    });
  };

  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = async () => {
    const isValidateForm = await formikRef.current.validateForm();
    if (
      !isValidateForm.email &&
      !isValidateForm.firstAndLastName &&
      !isValidateForm.phoneNumber
    ) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const handleDateChange = (date) => {
    setSelectedDate(new Date(date).toLocaleDateString("en-IN"));
    handleCloseDateModal();
    setCheckoutPageData((prevCheckoutPageData) => {
      const updatedCheckoutPageData = new Map(prevCheckoutPageData);
      updatedCheckoutPageData.set("date", date);
      return updatedCheckoutPageData;
    });
  };
  // const handleEmirateChange = (event) => {
  //   setSelectedEmirate(event.target.value);
  // };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };

  const validationSchema = yup.object({
    // selectedEmirate: yup.string().required("Emirate is required"),
    firstAndLastName: yup
      .string()
      .required("First And Last Name is required")
      .min(3, "First And Last Name must be at least 3 characters")
      .matches(
        /^[A-Za-z\s]+$/,
        "First And Last Name must contain only alphabetic characters"
      ),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(7, "Phone number must atleast 7 digits")
      .max(12, "Phone number not more then atleast 12 digits")
      .matches(/^[0-9]+$/, "Phone number can only contain numbers"),
  });

  const today = dayjs();
  const tomorrow = dayjs().add(3, "day");

  const priceCalculator = (selectedDay, selectedWeek) => {
    const params = {
      meal_plan: localStorage.getItem("mealId"),
      diet_plan: JSON.parse(localStorage.getItem("plan")).id,
      day: selectedDay ? selectedDay : day,
      week: selectedWeek ? selectedWeek : week,
      coupon_code: promoCode,
    };

    setLoader(true);
    request(
      Constant.URL.baseUrl +
        selectedLanguage +
        "/" +
        Constant.URL.PriceCalculator,
      "post",
      params,
      "",
      (error, result) => {
        setLoader(false);
        if (error) {
          setApiError(error?.response?.data?.message);
        } else {
          if (result.status == 200) {
            const price_calculator = result.data.data;
            price_calculator.breakdown_amount =
              price_calculator.price - price_calculator.discount;
            const price_calculator_summary = result.data.summary;
            setPriceCalculatorData(price_calculator);
            setPlan(price_calculator_summary?.plan);
            setDiscount(price_calculator_summary?.discount);
            setAmount(price_calculator_summary?.amount);
            setDeposit(price_calculator_summary?.deposit);
            setDueamount(price_calculator_summary?.due_amount);
            setDuenow(price_calculator_summary?.due_now);
          } else if (result.status == 401) {
            setApiError(result?.data?.message);
          } else {
            setApiError(result?.response?.data?.message);
          }
        }
      }
    );
  };

  const handleSubmit = (values) => {
    const params = {
      meal_plan: localStorage.getItem("mealId"),
      diet_plan: JSON.parse(localStorage.getItem("plan")).id,
      day: day,
      week: week,
      coupon_code: promoCode,
      start_date: selectedDate,
      customer: {
        fname: values.firstAndLastName.trim(),
        phone: Number(values.phoneNumber),
        email: values.email,
      },
      main_source_of_payment: paymentMethod,
      // delivery_location:selectedEmirate,
    };
    setLoader(true);
    request(Constant.URL.Checkout, "post", params, "", (error, result) => {
      setLoader(false);
      if (error) {
        setApiError(error?.response?.data.message);
      } else {
        if (result.status == 200) {
          const jsonData = JSON.stringify(result.data.data);
          localStorage.setItem("payment-data", jsonData);
          navigate("/stripe-checkout");
        } else if (result.status == 401) {
          setApiError(result?.data?.message);
        } else {
          setApiError(result?.response?.data?.message);
        }
      }
    });
  };

  const shouldDisableDate = (date) => {
    const day = new Date(date);
    const dayOfWeek = day.getDay();
    return isSunday(dayOfWeek);
  };

  function getDefaultValue(value) {
    const valueFromLocalStorage = localStorage.getItem(value);
    const valueFromCheckoutPageData = checkoutPageData.get(value);
    return valueFromLocalStorage || valueFromCheckoutPageData || "";
  }
  // const fetchEmirates = () => {
  //   setLoader(true);
  //   request(
  //     Constant.URL.baseUrl+ selectedLanguage + '/'+Constant.URL.Emirates,
  //     "get", "", "", (error, result) => {
  //     setLoader(false);
  //     if (error) {
  //     } else {
  //       if (result.status == 200) {
  //         setEmirates(result.data.data);
  //       }
  //     }
  //   });
  // };
  useEffect(() => {
    // fetchEmirates();
    if (checkoutPageData.size === 0) {
      if (
        location.state == null ||
        location.state == "" ||
        location.state?.plan == null ||
        location.state?.plan == ""
      ) {
        navigate(`/`);
      }
    }
    priceCalculator();
  }, [week, day]);

  useEffect(() => {
    const isValidated = validatecustomerLeads(customerLeads, prevCustomerLeads);
    if (isValidated) {
      request(
        Constant.URL.baseUrl + Constant.URL.SavePipeDrive,
        "post",
        customerLeads,
        "",
        (error, result) => {}
      );
      setPrevCustomerLeads({ ...customerLeads });
      setCheckoutPageData((prevCheckoutPageData) => {
        const updatedCheckoutPageData = new Map(prevCheckoutPageData);
        updatedCheckoutPageData.set("name", customerLeads.name);
        updatedCheckoutPageData.set("phone", customerLeads.phone);
        updatedCheckoutPageData.set("email", customerLeads.email);
        return updatedCheckoutPageData;
      });
    }
  }, [customerLeads, prevCustomerLeads, selectedLanguage]);

  const handlePromoCode = (e) => {
    setPromoCode(e.target.value);
    setCheckoutPageData((prevCheckoutPageData) => {
      const updatedCheckoutPageData = new Map(prevCheckoutPageData);
      updatedCheckoutPageData.set("promoCode", e.target.value);
      return updatedCheckoutPageData;
    });
  };

  useEffect(() => {
    const dataObject = Object.fromEntries(checkoutPageData.entries());
    const jsonData = JSON.stringify(dataObject);
    localStorage.setItem("checkoutPageData", jsonData);
  }, [checkoutPageData]);

  useEffect(() => {
    const activeTabIndex = checkoutPageData?.get("activeTabDays");
    const selectedWeekIndex = checkoutPageData?.get("selectedWeek");
    if (activeTabIndex) {
      setDay(JSON.parse(localStorage.getItem("days"))[activeTabIndex]);
    } else {
      setDay(JSON.parse(localStorage.getItem("days"))[0]);
    }
    if (selectedWeekIndex) {
      setWeek(JSON.parse(localStorage.getItem("week"))[selectedWeekIndex]);
    } else {
      setWeek(JSON.parse(localStorage.getItem("week"))[0]);
    }
    if (checkoutPageData.get("date")) {
      const date = new Date(checkoutPageData.get("date"));
      setSelectedDate(date.toLocaleDateString("en-IN"));
    }
    if (checkoutPageData.get("promoCode")) {
      setPromoCode(checkoutPageData.get("promoCode"));
    }
  }, [checkoutPageData]);

  return (
    <div className="checkout-wrapper md:mb-14">
      <Loader isLoading={loader} />
      <div className="custom-container checkout-box">
        <div className="flex flex-row justify-around md:justify-center items-center px-3 md:px-0">
          <h2 className="section-title ">Checkout</h2>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, errors, handleSubmit }) => (
            <div className="custom-border grid grid-cols-1 md:grid-cols-3 my-9">
              <div className="md:col-span-2">
                <Form>
                  <div className="checkout-wrapper-left mt-8">
                    <div className="checkout pb-8">
                      <div className="checkout-form px-4 md:px-8">
                        <h4 className="checkout-heading">
                          <Language>contact_details</Language>
                        </h4>

                        <div className="contact-info mt-5 md:mt-3 px-8 md:px-0">
                          <Grid container columnSpacing={2}>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextInputField
                                name="firstAndLastName"
                                defaultValue={getDefaultValue("name")}
                                type="text"
                                wrapperClassName="w-full"
                                inputClassName="form-control"
                                placeholder="first_and_last_name"
                                onBlur={(e) =>
                                  setCustomerLeads((prevCustomerLeads) => ({
                                    ...prevCustomerLeads,
                                    name: e.target.value,
                                  }))
                                }
                                value={values.firstAndLastName}
                                onChange={handleChange("firstAndLastName")}
                              />
                              <div style={{ color: "red", fontSize: 12 }}>
                                {errors.firstAndLastName}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextInputField
                                name="email"
                                type="text"
                                defaultValue={getDefaultValue("email")}
                                wrapperClassName="w-full"
                                inputClassName="form-control"
                                placeholder="email"
                                value={values.email}
                                onChange={handleChange("email")}
                                onBlur={(e) =>
                                  setCustomerLeads((prevCustomerLeads) => ({
                                    ...prevCustomerLeads,
                                    email: e.target.value,
                                  }))
                                }
                              />
                              <div style={{ color: "red", fontSize: 12 }}>
                                {errors.email}
                              </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                              <TextInputField
                                name="phoneNumber"
                                type="number"
                                defaultValue={getDefaultValue("phone")}
                                wrapperClassName="w-full"
                                inputClassName="form-control"
                                placeholder="phone_number"
                                value={values.phoneNumber}
                                onChange={handleChange("phoneNumber")}
                                onBlur={(e) =>
                                  setCustomerLeads((prevCustomerLeads) => ({
                                    ...prevCustomerLeads,
                                    phone: e.target.value,
                                  }))
                                }
                              />
                              <div style={{ color: "red", fontSize: 12 }}>
                                {errors.phoneNumber}
                              </div>
                            </Grid>
                          </Grid>
                        </div>

                        {/* <div className="choose-emirates mt-4">
                        <h4 className="checkout-heading"><Language>choose_emirates</Language></h4>
                        <Grid item xs={12}>
                            <div className="my-2">
                            <Select
                                  className={`emirate-select form-control ${errors.selectedEmirate ? 'is-invalid' : ''}`}
                                  name="emirates"
                                  value={selectedEmirate} 
                                  onChange={handleEmirateChange} 
                                  displayEmpty
                            >
                                <MenuItem className="select_menuitem" value="" disabled>
                                  <Language>emirate</Language>
                                </MenuItem>
                                {emirates.map((emirate) => (
                                  <MenuItem key={emirate.id} value={emirate.id}>
                                    {emirate.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                        </Grid>
                        </div> */}

                        <div className="how-many-days mt-4">
                          <div className="title-text">
                            <Language>how_many_days_per_week</Language>
                          </div>
                          <div className="tabs mt-5 md:mt-3">
                            {JSON.parse(localStorage.getItem("days")).map(
                              (selectedDay, index) => (
                                <div
                                  className={`tab ${
                                    activeTabDays === index ? "active" : ""
                                  }`}
                                  onClick={() => (
                                    handleTabDaysClick(index, selectedDay),
                                    setDay(selectedDay)
                                  )}
                                >
                                  <div className="days">
                                    {selectedDay}{" "}
                                    <Language>days_per_week</Language>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        <div className="how-many-week mt-8">
                          <div className="title-text">
                            <Language>plan_duration</Language>
                          </div>
                          <div className="tabs mt-5 md:mt-3">
                            {JSON.parse(localStorage.getItem("week")).map(
                              (selectedWeek, index) => (
                                <div
                                  className={`tab ${
                                    activeTabWeeks === index ? "active" : ""
                                  }`}
                                  onClick={() => (
                                    handleTabWeeksClick(index, selectedWeek),
                                    setWeek(selectedWeek)
                                  )}
                                >
                                  {/* <div className="days">{x}-Week Plan</div> */}
                                  <div className="days">
                                    {selectedLanguage === "en"
                                      ? `${selectedWeek} ${t("week")} ${t(
                                          "plan_duration"
                                        )}`
                                      : `${t("week")} ${selectedWeek} ${t(
                                          "plan_duration"
                                        )}`}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>

                        <Grid
                          container
                          className="start-your-plan mt-7 md:mt-12"
                        >
                          <Grid xs={12} sm={8}>
                            <h4 className="plan-heading">
                              <Language>when_you_want_to_start</Language>
                            </h4>
                            <p className="plan-desc mt-2 md:mt-3">
                              <Language>
                                remember_you_can_pause_plan_at_any_time
                              </Language>
                            </p>
                          </Grid>
                          <Grid xs={12} sm={4}>
                            <div className="justify-center flex md:justify-end">
                              <div className="date-picker mt-4 md:mt-0 inline">
                                <label>
                                  {selectedDate ? (
                                    selectedDate
                                  ) : (
                                    <Language>choose_date</Language>
                                  )}
                                </label>
                                <CalendarMonthOutlinedIcon
                                  className="date-icon ml-3"
                                  onClick={handleOpenDateModal}
                                />
                                <Modal
                                  aria-labelledby="transition-modal-title"
                                  aria-describedby="transition-modal-description"
                                  open={openDateModal}
                                  onClose={handleCloseDateModal}
                                  closeAfterTransition
                                  slots={{ backdrop: Backdrop }}
                                  slotProps={{
                                    backdrop: {
                                      timeout: 500,
                                    },
                                  }}
                                >
                                  <Fade in={openDateModal}>
                                    <Box sx={style} className="date-modal">
                                      <Grid container>
                                        <Grid xs={12}>
                                          <div className="md:hidden flex flex-col justify-center items-center mb-10">
                                            <ClearIcon
                                              onClick={handleCloseDateModal}
                                              style={{
                                                color: "#1b4633",
                                                fontSize: 45,
                                              }}
                                            />
                                            <p className="date-title mt-5">
                                              <Language>
                                                when_you_want_to_start
                                              </Language>
                                            </p>
                                            <p className="date-detail">
                                              <Language>
                                                remember_you_can_pause_plan_at_any_time
                                              </Language>
                                            </p>
                                          </div>
                                        </Grid>
                                        <Grid xs={12}>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <div className=""></div>
                                            <Grid
                                              xs={12}
                                              className="flex flex-row justify-center"
                                            >
                                              <div className="date-box">
                                                <label className="mr-4">
                                                  {selectedDate ? (
                                                    selectedDate
                                                  ) : (
                                                    <Language>
                                                      choose_date
                                                    </Language>
                                                  )}
                                                </label>
                                                <CalendarMonthOutlinedIcon className="date-modal-icon" />
                                              </div>
                                            </Grid>
                                            <DateCalendar
                                              shouldDisableDate={
                                                shouldDisableDate
                                              }
                                              dateFormat="yyyy/MM/dd"
                                              selected={selectedDate}
                                              onChange={handleDateChange}
                                              defaultValue={today}
                                              minDate={tomorrow}
                                            />
                                          </LocalizationProvider>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Fade>
                                </Modal>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="flex justify-center">
                        <span className="md:hidden inline">
                          <Button
                            variant="contained"
                            type="button"
                            className="green-background-btn mt-0"
                            onClick={toggleVisibility}
                          >
                            view price
                          </Button>
                        </span>
                      </div>
                    </div>
                    {/* <div className="payment-method mt-10">
                      <h3 className="payment-method-heading"><Language>payment_method</Language></h3>
                      <div className="flex flex-col justify-center mt-4 px-6 md:px-0">
                        <Button
                          variant="contained"
                          type="button"
                          className="!mt-1 next-btn"
                        >
                          <Language>weekly_payment_with_postpay</Language>{" "}
                          <img className="ml-2" src={PostPayImg} alt="image" />
                        </Button>

                        <Button
                          variant="contained"
                          type="button"
                          className="!mt-1 next-btn"
                        >
                          <img src={VisaPay} alt="image" />
                          <img className="ml-2" src={Mastercard} alt="image" />
                        </Button>
                        <Button
                          variant="contained"
                          type="button"
                          className="!mt-1 next-btn"
                          onClick={() => setPaymentMethod("cod")}
                        >
                          <Language>pay_later</Language>
                        </Button>
                        <Button
                          variant="contained"
                          type="button"
                          className="!mt-4 checkout-btn"
                          onClick={handleSubmit}
                        >
                          <Language>complete_secure_checkout</Language>
                        </Button>
                      </div>
                    </div> */}
                    <div className="payment-method mt-10 hidden md:flex">
                      <Button
                        variant="contained"
                        type="button"
                        className="!mt-4 checkout-btn"
                        onClick={handleSubmit}
                      >
                        <Language>complete_secure_checkout</Language>
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
              <div
                className={` flex flex-col ${
                  isVisible ? "open-summery-parent" : "hidden-order-summery"
                } `}
              >
                <div className={`order-summary w-full`}>
                  <div className="md:hidden flex justify-center my-4">
                    <ClearIcon
                      onClick={() => setIsVisible(false)}
                      style={{ color: "#1b4633", fontSize: 45 }}
                    />
                  </div>
                  <div className="order-summary-heading mt-3">
                    <Language>order_summary</Language>
                  </div>

                  <div className="apply-code">
                    <TextInputField
                      type="text"
                      wrapperClassName="w-full"
                      inputClassName="form-control"
                      placeholder="enter_promo_code"
                      value={promoCode}
                      onChange={(e) => handlePromoCode(e)}
                    />
                    <div style={{ color: "red", fontSize: 13 }}>
                      {priceCalculatorData.coupon_details?.status === false
                        ? priceCalculatorData.coupon_details?.message
                        : ""}
                    </div>
                    <Button
                      variant="contained"
                      type="button"
                      className="!mt-1 submit-btn"
                      onClick={() => priceCalculator()}
                    >
                      <Language>submit</Language>
                    </Button>
                  </div>

                  <div className="order-discounts">
                    <Grid container>
                      <Grid xs={12} sm={7}>
                        <div className="flex flex-col justify-center items-center md:items-start md:justify-start">
                          <h2 className="title">{plan?.heading}</h2>
                          <div className="mt-3">
                            {plan?.details?.map((detail, index) => (
                              <p key={index} className="title-detail mt-1">
                                {detail}
                              </p>
                            ))}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={5}
                        className="flex items-center justify-center md:justify-end mt-3 md:mt-0"
                      >
                        <p className="plan-detail">
                          AED {priceCalculatorData?.price}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="order-discounts">
                    <Grid container>
                      <Grid xs={12} sm={7}>
                        <div className="flex flex-col justify-center items-center md:items-start md:justify-start">
                          <h2 className="title">{discount?.heading}</h2>
                          <p className="title-detail mt-3"></p>
                        </div>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={5}
                        className="flex items-center justify-center md:justify-end mt-3 md:mt-0"
                      >
                        <p className="plan-detail">
                          -AED {priceCalculatorData?.discount}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="order-discounts">
                    <Grid container>
                      <Grid xs={12} sm={7}>
                        <div className="flex flex-col justify-center items-center md:items-start md:justify-start">
                          <h2 className="title">{amount?.heading} </h2>
                        </div>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={5}
                        style={{ flexDirection: "column" }}
                        className="flex items-center md:items-end justify-center md:justify-end mt-3 md:mt-0"
                      >
                        <p className="plan-detail">
                          AED {priceCalculatorData?.breakdown_amount}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="order-discounts">
                    <Grid container>
                      <Grid xs={12} sm={7}>
                        <div className="flex flex-col justify-center items-center md:items-start md:justify-start">
                          <h2 className="title">{deposit?.heading}</h2>
                          <div className="mt3">
                            {deposit?.details?.map((detail, index) => (
                              <p key={index} className="title-detail mt-1">
                                {detail}
                              </p>
                            ))}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={5}
                        className="flex items-center justify-center md:justify-end mt-3 md:mt-0"
                      >
                        <p className="plan-detail">
                          AED {priceCalculatorData.deposit}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="order-discounts">
                    <Grid container>
                      <Grid xs={12} sm={7}>
                        <div className="flex justify-center items-center md:items-start md:justify-start">
                          <h2 className="title text-start">
                            {dueamount?.heading}
                          </h2>
                        </div>
                      </Grid>
                      <Grid
                        xs={12}
                        sm={5}
                        className="flex items-center justify-center md:justify-end mt-3 md:mt-0"
                      >
                        <p className="plan-detail">
                          AED {priceCalculatorData.total}
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="order-discounts">
                    <Grid container>
                      <Grid xs={12} sm={12}>
                        <div className="flex flex-col justify-center items-center">
                          <h2 className="due-now">
                            <Language>due_now</Language>
                          </h2>
                          <h5 className="due-price mt-2">
                            AED {priceCalculatorData.total}
                          </h5>
                          <p className="due-detail">
                            <Language>including_bag_deposit</Language>
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="flex justify-center mt-10">
                    <span className="md:hidden inline">
                      <Button
                        variant="contained"
                        type="button"
                        className="!mt-4 checkout-btn"
                        onClick={handleSubmit}
                      >
                        <Language>complete_secure_checkout</Language>
                      </Button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Checkout;
